import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Slider from "react-slick";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const StyledSlider = styled(Slider)`
  .slick-prev {
    left: 43px;
    z-index: 1;
  }

  .slick-next {
    right: 43px;
  }
`;

export const StyledSlide = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  min-height: 588px;

  ${media.tablet} {
    min-height: 1024px;
  }

  ${media.laptop} {
    flex-direction: row;
    min-height: auto;
  }
`;

export const Column = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;

  ${media.laptop} {
    width: 50%;
    min-height: 764px;
  }

  ${media.desktop} {
    min-height: 970px;
  }
`;

export const StyledBackgroundImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const StyledImage = styled(Image)`
  align-self: end;
  width: 100%;
  max-width: 30%;
  margin-top: 25px;
  margin-bottom: 25px;

  ${media.tablet} {
    max-width: 290px;
    margin-top: 50px;
    margin-bottom: 45px;
  }

  ${media.laptop} {
    max-width: 416px;
  }

  ${media.desktop} {
    max-width: 522px;
  }
`;

export const StyledParagraph = styled(ReactMarkdown)`
  p {
    margin: 0;
    padding: 20px 34px 21px 36px;
    background-color: #fff;
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    column-count: 2;
    column-gap: 20px;

    ${media.tablet} {
      padding: 24px 38px 25px 40px;
      font-size: 16px;
      line-height: 24px;
      column-gap: 30px;
    }

    ${media.laptop} {
      padding: 45px 44px 35px 43px;
      column-gap: 40px;
    }

    ${media.desktop} {
      padding: 52px 37px 52px 43px;
    }
  }
`;
