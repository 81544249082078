import React, { useRef, useEffect } from "react";
import { StyledHeroImage, StyledHeroImageContainer } from "../styles";

const ImageSlider = ({ currentIndex, previousIndex, data }) => {
  const slideshowRef = useRef(null);

  const getImageStatus = ({ currentIndex, targetIndex, initialLoad }) => {
    if (currentIndex === targetIndex) {
      return initialLoad ? "initial-image" : "next-image";
    }

    if (previousIndex === targetIndex) {
      return initialLoad ? "inactive-image" : "last-image";
    }

    return "inactive-image";
  };

  useEffect(() => {
    // stateful classNames cause re-renders which in turn causes animation image flickering.
    // Imperative DOM manipulation provides better perf
    const initialLoad = previousIndex === null;
    const span = slideshowRef?.current ?? null;
    const children = (span && Array.from(span.children)) || [];

    if (!children.length) {
      return;
    }

    children.map((child, idx) => {
      const classNameArray = child.className.split(" ");
      const newClassName = getImageStatus({
        targetIndex: idx,
        currentIndex,
        initialLoad,
      });

      if (initialLoad) {
        child.className = [...classNameArray, newClassName].join(" ");
      } else {
        child.className = [
          ...classNameArray.slice(0, classNameArray.length - 1),
          newClassName,
        ].join(" ");
      }

      return child;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <StyledHeroImageContainer ref={slideshowRef}>
      {data.map(({ ssHeroImage }, index) => {
        return (
          <StyledHeroImage
            key={`${index}-sshero-slide-image`}
            item={ssHeroImage}
            objectFit="cover"
            alt=""
            loading="eager"
          />
        );
      })}
    </StyledHeroImageContainer>
  );
};

export default ImageSlider;
