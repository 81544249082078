import React from "react";
import {
  Container,
  StyledHeaderContainer,
  StyledHeaderTime,
  StyledHeaderTitle,
  StyledContentContainer,
  StyledContentImageContainer,
  StyledContentImage,
  StyledVerticalDivider,
  StyledDescriptionContainer,
  StyledDescriptionTitle,
  StyledDescription,
  StyledAppImage,
} from "./styles";

const TimelineHeader = ({ time, title }) => (
  <StyledHeaderContainer>
    <StyledHeaderTime>{time}</StyledHeaderTime>
    <StyledHeaderTitle>{title}</StyledHeaderTitle>
  </StyledHeaderContainer>
);

const TimelineContent = ({ image, position, title, description, appImage }) => (
  <StyledContentContainer image_align={position}>
    <StyledContentImageContainer>
      <StyledContentImage item={image} objectFit="cover" />
    </StyledContentImageContainer>
    <StyledDescriptionContainer>
      <StyledDescriptionTitle>{title}</StyledDescriptionTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledDescriptionContainer>
    <StyledVerticalDivider position="top" />
    <StyledAppImage item={appImage} objectFit="contain" />
    <StyledVerticalDivider position="bottom" />
  </StyledContentContainer>
);

const TimelineWidget = ({ timelineItems = [] }) => {
  const content =
    timelineItems?.map(
      (
        {
          timelineAnchorID,
          timelineHeaderTime,
          timelineHeaderTitle,
          timelineContentImage,
          timelineContentImagePosition,
          timelineDescriptionTitle,
          timelineDescription,
          timelineAppImage,
        },
        index
      ) => (
        <React.Fragment
          key={`${timelineHeaderTime}-${timelineAnchorID}-${index}`}
        >
          <TimelineHeader
            time={timelineHeaderTime}
            title={timelineHeaderTitle}
            andchorID={timelineAnchorID}
          />
          <TimelineContent
            image={timelineContentImage}
            position={timelineContentImagePosition}
            title={timelineDescriptionTitle}
            description={timelineDescription}
            appImage={timelineAppImage}
          />
        </React.Fragment>
      )
    ) || [];

  return <Container>{content}</Container>;
};

export default TimelineWidget;
