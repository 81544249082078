import React from "react";
import { Container, Content } from "./styles";
import useCurrentDevice from "../../utils/useCurrentDevice";
import { Devices } from "../../consts/devices";
import NewsTileItem from "./NewsTileItem";

const NewsTiles = ({ items }) => {
  const currentDevice = useCurrentDevice();
  const isMobile = [Devices.MOBILE, Devices.TABLET].includes(currentDevice);

  const generateNewsTileItems = ({ linkUrl, label, image, date, index }) => (
    <NewsTileItem
      key={`${label}-${index}`}
      index={index}
      linkUrl={linkUrl}
      label={label}
      image={image}
      date={date}
      isMobile={isMobile}
    />
  );

  return (
    <Container>
      <Content>
        {items.map((item, index) => generateNewsTileItems({ index, ...item }))}
      </Content>
    </Container>
  );
};

export default NewsTiles;
