import React, { useCallback, useState } from "react";
import {
  Container,
  ListWrapper,
  ListWrapperItem,
  ListWrapperItemIcon,
  ListWrapperItemText,
  ContentWrapper,
  Title,
} from "./styles";
import Button from "components/Button";
import { ButtonTypes } from "components/Button/types";
import Modal from "components/Modal";
import TableInModal from "./TableInModal";

const ListWithIcons = ({ title, rows = [], modalRows = [] }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const hadleClickToOpen = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);

  const handleClickToCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  return (
    <Container>
      <Modal isOpen={isOpenModal} onClose={handleClickToCloseModal}>
        <TableInModal modalRows={modalRows} />
      </Modal>
      <ContentWrapper>
        <Title>{title}</Title>
        <ListWrapper>
          {rows.map(({ rowsText }, index) => (
            <ListWrapperItem key={index}>
              <ListWrapperItemIcon type="CheckCircleOutline" />
              <ListWrapperItemText>{rowsText}</ListWrapperItemText>
            </ListWrapperItem>
          ))}
        </ListWrapper>
        <Button onClick={hadleClickToOpen} type={ButtonTypes.ORANGE}>
          Learn More
        </Button>
      </ContentWrapper>
    </Container>
  );
};

export default ListWithIcons;
