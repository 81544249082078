import styled from "styled-components";
import Slider from "react-slick";
import { media } from "components/Theme/default-theme";
import Modal from "react-modal";
import HamburgerOpened from "../../../static/icons/HamburgerOpened.svg";
import Image from "components/Image";

export const StyledSlider = styled(Slider)`
  height: 100%;

  .slick-prev {
    left: -35px;
  }

  .slick-next {
    right: -35px;
  }

  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  ${media.mobile} {
    .slick-prev {
      left: -70px;
    }

    .slick-next {
      right: -70px;
    }
  }
`;
export const StyledSlideOuter = styled.div`
  height: 100%;
`;

export const StyledSlide = styled.div`
  position: relative;
  height: 100%;
`;

export const StyledSliderImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 56px 50px;

  ${media.mobile} {
    padding: 56px 90px;
  }

  ${media.laptop} {
    padding: 98px 100px;
  }
`;

export const StyledHamburgerOpened = styled(HamburgerOpened)`
  position: absolute;
  top: 17px;
  right: 24px;
  cursor: pointer;

  ${media.mobile} {
    top: 27px;
    right: 33px;
  }

  ${media.laptop} {
    top: 47px;
    right: 52px;
  }
`;
