import styled from "styled-components";
import Link from "gatsby-link";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const StyledCaseStudyName = styled.span`
  display: inline-block;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: 20px;
  line-height: 125%;
  color: #382e2b;
  text-decoration: none;
  margin: 20px;
  margin: clamp(0.625rem, 0.3752rem + 0.8326vw, 1.25rem) 0;
`;

export const StyledCaseStudyImage = styled(Image)`
  aspect-ratio: 1;
  width: 100%;
`;

export const StyledCaseStudy = styled(Link)`
  display: inline-block;
  cursor: pointer;
  margin-bottom: 45px;
  max-width: 247px;

  :hover ${StyledCaseStudyName} {
    text-decoration: underline;
  }
`;

export const StyledCaseStudiesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.75rem;
`;

export const Header = styled.h2`
  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-transform: uppercase;
  color: #382e2b;
  margin: 0 70px 46px 0;
  width: 100%;
  text-align: center;
`;

export const Container = styled.div`
  position: relative;

  ${media.tablet} {
    max-width: 556px;
    margin: 0 auto;
    padding: 160px 0 150px;

    ${Header} {
      text-align: left;
    }

    ${StyledCaseStudiesList} {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0;
      ${StyledCaseStudy} {
        flex: 0 1 45%;
      }
    }
  }

  ${media.laptop} {
    max-width: 967px;
    padding-top: 200px;
    ${StyledCaseStudiesList} {
      ${StyledCaseStudy} {
        flex: 0 1 28%;
        max-width: 277px;
      }
    }
  }

  ${media.desktop} {
    max-width: 1311px;
    padding-top: 210px;
    ${StyledCaseStudiesList} {
      ${StyledCaseStudy} {
        flex: 0 1 20%;
      }
    }
  }
`;

export const StyledSeparator = styled.div`
  position: relative;

  ::after {
    content: "";
    position: absolute;
    top: 46px;
    left: 0;
    right: 100%;
    height: 1px;
    background-color: #979797;
    transition: all 1s ease-in-out 0.3s;

    ${media.laptop} {
      top: 63px;
    }

    ${media.laptop} {
      top: 102px;
    }

    ${media.desktop} {
      top: 106px;
    }
  }

  &.aos-animate::after {
    right: 0;
  }
`;
