import styled from "styled-components";
import Button from "components/Button";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";

export const StyledDescription = styled.p`
  width: 304px;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  color: #382e2b;
  font-size: 14px;
  line-height: 25px;
  margin: 0;
`;

export const StyledIFrame = styled.iframe`
  width: 298px;
  height: 379px;
`;

export const PlaylistContainer = styled.span`
  display: inherit;
  flex-direction: column;
  align-items: center;
  gap: 13px;
`;

export const StyledImage = styled(Image)`
  height: 100%;
  width: 80%;
`;

export const StyledLinkButton = styled(Button)`
  border: 1px solid #382e2b;
  border-radius: 7.2625px;
  height: 77px !important;
  width: 291px !important;
  padding: 0;
`;

export const ButtonListContainer = styled.span`
  display: inherit;
  flex-direction: column;
  gap: 35px;
  align-items: center;
  margin: 60px auto;
`;

export const StyledTitle = styled.h2`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
  font-size: 21px;
  line-height: 26px;
`;

export const Container = styled.span`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 100px 10px;

  ${media.tablet} {
    margin: 100px 60px;

    ${StyledTitle} {
      font-size: 40px;
      line-height: 50px;
    }

    ${ButtonListContainer} {
      flex-direction: row;
      gap: 176px;
      justify-content: center;
      margin: 80px 0;
      flex-wrap: wrap;
    }

    ${PlaylistContainer} {
      flex-direction: row;
      gap: 176px;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;

      ${StyledDescription} {
        font-size: 24px;
        line-height: 36px;
        width: 285px;
        height: 288px;
      }
    }
  }
`;
