import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "../../../components/Theme/default-theme";
import { getJustifyContentByContentAlign } from "../../../utils/stylesHelpers";

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({ contentAlign }) =>
    getJustifyContentByContentAlign(contentAlign)};
`;

export const Paragraph = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 25px;
    color: #382e2b;

    ${media.laptop} {
      font-size: 24px;
      line-height: 36px;
    }

    ${media.desktop} {
      font-size: 24px;
      line-height: 36px;
      ${({ desktopLargeText }) =>
        desktopLargeText &&
        css`
          font-size: 25px;
          line-height: 38px;
        `}
    }
  }
`;

export const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 14px;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  color: #382e2b;

  ${media.laptop} {
    font-size: 40px;
    line-height: 50px;
  }

  ${media.desktop} {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const Content = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  text-align: ${({ contentAlign }) => contentAlign};
  height: 50%;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 304px;
  ${({ is_mobile }) =>
    is_mobile ? "margin: 33px auto" : "margin: 170px auto"};
  flex-direction: column;

  ${media.tablet} {
    max-width: 510px;
  }

  ${media.laptop} {
    padding-top: 55px;
    max-width: 891px;
  }

  ${media.desktop} {
    padding-top: 55px;
    max-width: 1043px;
  }
`;
