import React from "react";
import {
  Container,
  Grid,
  GridItem,
  StyledImage,
  GridRow,
  Title,
} from "./styles";
import PhotoGalleryPreview, {
  usePhotoGalleryPreview,
} from "../PhotoGalleryPreview";

const ImageCustomGrid = ({ title, gridImages }) => {
  const { initialSlide, handleClickBySlideIndex, onClose } =
    usePhotoGalleryPreview();

  const reduceGridItem = (prev, curr, index) => {
    if (prev.length) {
      const lastItemIndex = prev.length - 1;
      if (prev[lastItemIndex].length < 2) {
        prev[lastItemIndex].push(curr);
      } else {
        prev.push([curr]);
      }
    } else {
      prev.push([curr]);
    }
    return prev;
  };

  const calcIndex = (rowIndex, itemIndex) => {
    if (rowIndex % 2) {
      return itemIndex ? 1 : 0;
    } else return itemIndex ? 0 : 1;
  };

  let slideIndex = 0;

  return (
    <Container>
      <Title
        data-aos="fade"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-once="true"
      >
        {title}
      </Title>
      <Grid>
        {gridImages.reduce(reduceGridItem, []).map((row, rowIndex) => (
          <GridRow key={rowIndex}>
            {row.map((item, itemIndex) => (
              <GridItem
                data-aos="fade-right"
                data-aos-duration={itemIndex % 2 === 0 ? 900 : 600}
                data-aos-easing="ease-in-out"
                data-aos-once="true"
                cellSize={calcIndex(rowIndex, itemIndex)}
                key={`${rowIndex} - ${itemIndex}`}
                onClick={handleClickBySlideIndex(slideIndex++)}
              >
                <StyledImage item={item} alt={"Grid Item Image"} />
              </GridItem>
            ))}
          </GridRow>
        ))}
      </Grid>
      <PhotoGalleryPreview
        images={gridImages}
        initialSlide={initialSlide}
        onClose={onClose}
      />
    </Container>
  );
};

export default ImageCustomGrid;
