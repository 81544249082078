import styled, { css } from "styled-components";
import Link from "gatsby-link";
import ReactMarkdown from "react-markdown";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const Container = styled.div`
  position: relative;
`;

export const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 55px 0;

  ${media.laptop} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${media.laptop} {
    flex-direction: row;
    width: 50%;
  }
`;

export const StyledFullScreenImage = styled(Image)`
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
`;

export const StyledImage = styled(Image)`
  width: 100%;
  max-width: 240px;
  ${({ reverseMode }) =>
    !reverseMode &&
    css`
      margin-bottom: 25px;
    `}

  ${media.tablet} {
    max-width: 350px;
    ${({ reverseMode }) =>
      !reverseMode &&
      css`
        margin-bottom: 45px;
      `}
  }

  ${media.laptop} {
    max-width: 350px;
    margin-bottom: 0;
  }

  ${media.laptop} {
    max-width: 450px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 20px;
  max-width: 264px;

  ${media.tablet} {
    max-width: 510px;
  }

  ${media.laptop} {
    padding: 237px 0;
    max-width: 545px;
  }

  ${media.desktop} {
    padding: 134px 0;
    max-width: 640px;
  }
`;

export const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 9px 14.5px 7px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  text-transform: uppercase;
  margin-top: 24px;

  &:hover {
    background: rgba(67, 68, 70, 0.6);
    border: 1px solid #9f9f9f;
  }

  ${media.tablet} {
    font-size: 16px;
    line-height: 19px;
    padding: 17px 23.5px 12px;
  }
`;

export const StyledTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 23px;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  color: #fff;

  ${media.tablet} {
    font-size: 28px;
    line-height: 35px;
  }

  ${media.desktop} {
    font-size: 35px;
    line-height: 44px;
  }
`;

export const StyledParagraph = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    color: #fff;

    ${media.tablet} {
      font-size: 20px;
      line-height: 30px;
    }

    ${media.desktop} {
      font-size: 25px;
      line-height: 38px;
    }
  }
`;
