import React from "react";
import {
  Container,
  Grid,
  GridItem,
  GridRow,
  StyledImage,
  Subtitle,
  TextWrapper,
  Title,
} from "./styles";

const IconAndTextGrid = ({ listItemsIconAndText }) => {
  return (
    <Container>
      <Grid>
        {listItemsIconAndText.map((item, index) => {
          const { leftIcon, rightIcon } = item;

          return (
            <GridRow key={`grid-row-item-${index}`}>
              <GridItem to={item.leftLink}>
                {leftIcon && (
                  <StyledImage
                    item={leftIcon}
                    alt={`left-icontext-img-${index}`}
                  />
                )}
                <TextWrapper>
                  <Title>{item.leftTitle}</Title>
                  <Subtitle>{item.leftSubtitle}</Subtitle>
                </TextWrapper>
              </GridItem>
              <GridItem to={item.rightLink}>
                {rightIcon && (
                  <StyledImage
                    item={rightIcon}
                    alt={`right-icontext-img-${index}`}
                  />
                )}
                <TextWrapper>
                  <Title>{item.rightTitle}</Title>
                  <Subtitle>{item.rightSubtitle}</Subtitle>
                </TextWrapper>
              </GridItem>
            </GridRow>
          );
        })}
      </Grid>
    </Container>
  );
};

export default IconAndTextGrid;
