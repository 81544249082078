import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;

export const Header2 = styled.h2`
  margin: 0 1.75rem;

  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
  padding: 3rem 0;
`;

export const Paragraph = styled(ReactMarkdown)`
  margin: 0 1.75rem;

  p {
    font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.5rem);
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 180%;
    color: #382e2b;
    margin: 0;
    padding: 5rem 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  ${media.tablet} {
    ${Header2}, ${StyledImage}, ${Paragraph} {
      margin: 0 auto;
      width: 80%;

      p {
        line-height: 180%;
      }
    }
  }
`;
