import NavLink from "gatsby-link";
import { Link } from "react-scroll";
import styled from "styled-components";

import Icon from "components/Icon";
import { media } from "components/Theme/default-theme";

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 9px;
  height: 60px;
  cursor: pointer;
`;

export const BtnTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  position: relative;
  transform: translateY(5px);
  color: #392e2b;
  margin: 0 0 0 6px;
  text-transform: uppercase;
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2;
  background: #f2f2f2;
  box-shadow: inset 0 -1px 0 #dbd9d7, inset 0 1px 0 #dbd9d7;
`;

export const NavAnimate = styled.div`
  overflow: hidden;
  transition: ${({ isOpen }) =>
    isOpen ? "max-height 0.3s ease-in" : "max-height 0.2s ease-out"};
  max-height: ${({ isOpen }) => (isOpen ? "400px" : "0px")};
`;

export const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 23px 31px;
`;

export const StyledIcon = styled(Icon)``;

export const NavigationLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #392e2b;
  margin-top: 5px;
  text-transform: uppercase;

  &:hover {
    color: #ff5f00;
    cursor: pointer;
  }
`;

export const GetSavantLink = styled(NavLink)``;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 60px;

  ${media.tablet} {
    ${GetSavantLink} {
      right: 40px;
    }

    ${BtnWrapper} {
      padding-left: 40px;

      ${BtnTitle} {
        transform: translateY(7px);
      }
    }

    ${NavWrapper} {
      padding: 0 53px 31px;
    }
  }

  ${media.laptop} {
    ${GetSavantLink} {
      right: 44px;
      font-size: 16px;
    }

    ${Content} {
      display: flex;

      ${BtnWrapper} {
        cursor: initial;
        padding-left: 44px;

        ${StyledIcon} {
          display: none;
        }

        ${BtnTitle} {
          text-transform: uppercase;
          transform: translateY(0);
          font-size: 16px;
          line-height: 19px;
        }
      }

      ${NavAnimate} {
        overflow: hidden;
        max-height: initial;

        ${NavWrapper} {
          height: 100%;
          flex-direction: row;
          align-items: center;
          padding: 0;

          ${NavigationLink} {
            height: 60px;
            display: block;
            margin: 0 0 0 30px;
            text-transform: uppercase;
            line-height: 60px;
            font-size: 16px;

            &:hover {
              border-bottom: 3px solid #ff6000;
            }
          }
        }
      }
    }
  }

  ${media.desktop} {
    ${BtnWrapper} {
      padding-left: 50px;
    }

    ${GetSavantLink} {
      right: 50px;
      font-size: 18px;
    }

    ${NavigationLink} {
      font-size: 18px;
    }
  }
`;
