import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Icon from "../../components/Icon";
import { media } from "../../components/Theme/default-theme";
import { getJustifyContentByContentAlign } from "../../utils/stylesHelpers";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 40px 0;
  background-image: ${({ image }) => (image ? "url(" + image + ")" : "none")};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: ${({ grey }) => (grey ? "#F2F2F2" : "#FFFFFF")};

  ${media.tablet} {
    width: 50%;
    padding: 100px 0;
  }

  ${media.laptop} {
    padding: 250px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  padding: 0 8px;

  ${media.tablet} {
    padding: 0;
  }
`;

export const StyledTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 22px;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  text-align: ${({ contentAlign }) => contentAlign};
  color: #696057;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${media.tablet} {
    font-size: 28px;
    line-height: 35px;
  }
`;

export const StyledText = styled(ReactMarkdown)`
  margin-top: 0;
  margin-bottom: 30px;

  p {
    margin: 0;
    font-size: 15px;
    line-height: 23px;
    color: #382e2b;
    text-align: ${({ contentAlign }) => contentAlign};

    ${media.tablet} {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;

export const StyledButtonWrapper = styled.div`
  display: none;

  ${media.tablet} {
    display: flex;
    justify-content: ${({ contentAlign }) =>
      getJustifyContentByContentAlign(contentAlign)};
    margin-top: auto;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 17px;
`;

export const StyledIcon = styled(Icon)`
  display: none;

  ${media.tablet} {
    display: flex;
    padding: 24px;
    background-color: #f8f8f8;
    border-radius: 50%;
  }
`;
