import React from "react";
import {
  Item,
  ItemImage,
  ItemLabelWrapper,
  ItemLabel,
  ItemDate,
} from "../styles";

const NewsTileItem = ({ index, isMobile, linkUrl, image, label, date }) => {
  return (
    <Item
      data-aos="fade-in"
      data-aos-offset={isMobile ? 200 : 300}
      data-aos-delay={isMobile ? 200 : ((index + 1) % 7) * 200}
      data-aos-duration="300"
      data-aos-easing="ease-in"
      data-aos-once="true"
    >
      {image && (
        <ItemImage
          item={image}
          alt={`in-news-item-image-${index}`}
          objectFit="contain"
        />
      )}
      <ItemLabelWrapper>
        <ItemLabel href={linkUrl}>"{label}"</ItemLabel>
        <ItemDate>{date}</ItemDate>
      </ItemLabelWrapper>
    </Item>
  );
};

export default NewsTileItem;
