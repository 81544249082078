import React from "react";
import ArticleContent from "./ArticleContent";
import { Container, ItemsWrapper } from "./styles";

const Article = ({ article }) => {
  return (
    <Container>
      <ItemsWrapper>
        {article && article?.length > 0 && <ArticleContent data={article} />}
      </ItemsWrapper>
    </Container>
  );
};

export default Article;
