import React, { useState, useEffect } from "react";
import { Container, FormSubtitle } from "./styles";
import PowerForm from "./PowerForm";
import SubmitSuccessModal from "components/SubmitSuccessModal";

const PowerIntakeFormWidget = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [retURL, setRetURL] = useState("");

  useEffect(() => {
    typeof window !== "undefined" && setRetURL(window.location.href);
  }, []);

  return (
    <Container>
      <iframe
        title="sf-power-project"
        id="sf-power-project"
        name="sf-power-project"
        src="about:blank"
        style={{ display: "none" }}
      />
      {submitSuccess && (
        <SubmitSuccessModal onCloseHandler={() => setSubmitSuccess(false)}>
          <FormSubtitle position="center">
            Your inquiry and Savant experience is very important to us.
            <br />
            Please expect one of our representatives to reach out to you within
            2 business days.
            <br />
            Thank you for your patience.
          </FormSubtitle>
        </SubmitSuccessModal>
      )}
      {!submitSuccess && (
        <PowerForm
          retURL={retURL}
          formSubmitCallback={() => setSubmitSuccess(true)}
        />
      )}
    </Container>
  );
};

export default PowerIntakeFormWidget;
