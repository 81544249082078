import React, { useRef } from "react";

import LearnMoreBtn from "components/LearnMoreBtn";

import {
  Container,
  StyledTitle,
  StyledImage,
  StyledMarkdown,
  Column,
  Content,
  ListIcons,
  StyledIconImage,
  Video,
  ContentContainer,
} from "./styles";

const VaryDevice = ({
  image,
  title,
  paragraph,
  reverseMode = false,
  contentAlign = "center",
  btnUrl,
  btnType,
  btnLabel,
  openInNewTab,
  listIcons = [],
  background,
  fontColor,
  marginTopForContent = false,
  enabledBigImage = false,
  video,
  loopVideo,
  showReplayButton,
  autoplayVideo,
  showVideoControls,
}) => {
  const videoRef = useRef();
  const iconList =
    listIcons?.map(({ iconImage }, index) => (
      <React.Fragment key={`icon-img-vary-device-${index}`}>
        <StyledIconImage alt="" item={iconImage} />
      </React.Fragment>
    )) || [];

  return (
    <Container
      background={background}
      font_color={fontColor}
      reverseMode={reverseMode}
    >
      <ContentContainer>
        {video && (
          <Video
            ref={videoRef}
            $imageBackground={background}
            url={video}
            autoplay={autoplayVideo}
            loop={loopVideo}
            volume={0}
            muted={true}
            object_fit="cover"
            controls={showVideoControls}
            showReplayButton={showReplayButton}
          />
        )}
        <Column
          className="vd-image-column"
          data-aos="fade"
          data-aos-delay="200"
          data-aos-duration="600"
          data-aos-once="true"
        >
          <StyledImage
            item={image}
            enabledBigImage={enabledBigImage}
            objectFit="contain"
          />
        </Column>
        <Column className="vd-text-column">
          <Content
            contentAlign={contentAlign}
            marginTopForContent={marginTopForContent}
            data-aos="fade"
            data-aos-delay="200"
            data-aos-duration="600"
            data-aos-once="true"
          >
            <StyledTitle>{title}</StyledTitle>
            {iconList?.length > 0 && <ListIcons>{iconList}</ListIcons>}
            <StyledMarkdown>{paragraph}</StyledMarkdown>
            <LearnMoreBtn
              btnUrl={btnUrl}
              btnType={btnType}
              openInNewTab={openInNewTab}
            >
              {btnLabel}
            </LearnMoreBtn>
          </Content>
        </Column>
      </ContentContainer>
    </Container>
  );
};
export default VaryDevice;
