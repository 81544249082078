import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import LearnMoreBtn from "components/LearnMoreBtn";

export const Container = styled.div`
  display: flex;
  margin: 150px 120px;
`;
export const Column = styled.div`
  width: 50%;

  :first-child {
    padding-right: 95px;
  }
`;
export const Title = styled.h3`
  margin: 0 0 20px;
  font-family: Gotham, sans-serif;
  font-size: 30px;
  line-height: 45px;
  color: #000;
`;
export const MarkdownBody = styled(ReactMarkdown)`
  p {
    font-family: Gotham, sans-serif;
    font-size: 20px;
    line-height: 30px;
    color: #000;
  }
`;
export const Section = styled.section``;
export const LinksList = styled.ul`
  margin: 0 0 40px;
  padding-left: 15px;
  color: #ff5f00;
`;
export const LinkListItem = styled.li``;

export const Link = styled(LearnMoreBtn)`
  margin: 0;
  font-family: Gotham, sans-serif;
  font-size: 20px;
  line-height: 30px;
  display: inline;
`;
