import React from "react";
import SliderArrow from "components/SliderArrow";
import {
  Container,
  StyledMasonry,
  StyledDevice,
  StyledImage,
  StyledTitle,
  StyledParagraph,
  StyledSlider,
  StyledSlide,
} from "./styles";
import { ArrowTypes } from "../../components/SliderArrow";

const AppShotStaggered = ({ devicesList = [] }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrow type={ArrowTypes.lightGrayToWhite} />,
    prevArrow: <SliderArrow isPrev type={ArrowTypes.lightGrayToWhite} />,
  };

  return (
    <Container>
      <StyledMasonry
        className=""
        columnClassName="masonry-grid-column"
        breakpointCols={2}
      >
        {devicesList.map(({ image, title = "", paragraph }, index) => (
          <StyledDevice
            key={index}
            shiftDown={index === 1}
            data-aos="fade-in"
            data-aos-offset="100"
            data-aos-delay={1 * index}
            data-aos-duration="400"
            data-aos-easing="ease-in"
            data-aos-once="true"
          >
            <StyledImage item={image} alt={title} />
            <StyledTitle>{title}</StyledTitle>
            <StyledParagraph>{paragraph}</StyledParagraph>
          </StyledDevice>
        ))}
      </StyledMasonry>

      <StyledSlider {...settings}>
        {devicesList.map(({ image, title = "", paragraph }, index) => (
          <StyledSlide key={index}>
            <StyledDevice>
              <StyledImage item={image} alt={title} />
              <StyledTitle>{title}</StyledTitle>
              <StyledParagraph>{paragraph}</StyledParagraph>
            </StyledDevice>
          </StyledSlide>
        ))}
      </StyledSlider>
    </Container>
  );
};

export default AppShotStaggered;
