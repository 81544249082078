import styled from "styled-components";
import LearnMoreBtn from "components/LearnMoreBtn";

export const ListSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListSectionTitle = styled.h3`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  margin: 0;
  font-style: normal;
`;

export const Padding = styled.div`
  padding: 20px 0;
`;

export const Link = styled(LearnMoreBtn)`
  text-align: left;
  text-transform: initial;
  text-decoration: none;
  color: #ff5f00;
  margin: 0;
  line-height: 25px;
  font-size: 16px;
  font-weight: 400;
`;
