import styled from "styled-components";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const InteractiveItemsWrapper = styled.span`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 60px;
  width: auto;
  top: 150px;
  left: 0;
  right: 0;
  margin: 0 20%;

  ${media.tablet} {
    top: 460px;
  }

  ${media.laptop} {
    top: 335px;
  }

  ${media.desktop} {
    top: 440px;
  }
`;

export const StyledBackgroundImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const StyledBackgroundImageWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 328px;

  ${media.tablet} {
    position: relative;
    height: 512px;
  }

  ${media.laptop} {
    height: 400px;
  }

  ${media.desktop} {
    height: 520px;
  }
`;

export const Container = styled.div`
  position: relative;
  margin-bottom: 70px;
  height: 100%;
`;
