import React from "react";
import LearnMoreBtn, {
  learnMoreBtnType,
} from "../../../components/LearnMoreBtn";
import WithAnimation from "../../../utils/withAnimation";
import { InteractiveContentAnimation } from "../constants";
import { Container, Content, Title, Paragraph, ButtonWrapper } from "./styles";

const InteractiveItemContent = ({
  item: { contentAlign, title, paragraph, linkUrl },
  largeText,
  isMobile = false,
}) => {
  return (
    <WithAnimation
      key={`${title}-${paragraph}`}
      animations={InteractiveContentAnimation}
    >
      <Container is_mobile={isMobile}>
        <Content contentAlign={contentAlign}>
          <Title>{title}</Title>
          <Paragraph desktopLargeText={largeText}>{paragraph}</Paragraph>
          <ButtonWrapper contentAlign={contentAlign}>
            <LearnMoreBtn
              btnUrl={linkUrl}
              btnType={learnMoreBtnType.fullOrangeBtn}
            />
          </ButtonWrapper>
        </Content>
      </Container>
    </WithAnimation>
  );
};

export default InteractiveItemContent;
