import React from "react";
import SliderArrow from "components/SliderArrow";
import {
  StyledSlider,
  StyledSlide,
  StyledBackgroundImage,
  StyledImage,
  StyledParagraph,
  Column,
} from "./styles";

const VirtualWalkThrough = ({ items }) => {
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    arrows: true,
    nextArrow: <SliderArrow />,
    prevArrow: <SliderArrow isPrev />,
  };

  return (
    <StyledSlider {...settings}>
      {items.map(({ paragraph, backgroundImage, deviceImage }, index) => (
        <div key={index}>
          <StyledSlide>
            <StyledBackgroundImage alt="" item={backgroundImage} />
            <Column>
              <StyledParagraph>{paragraph}</StyledParagraph>
            </Column>
            <Column>
              <StyledImage alt="" item={deviceImage} objectFit="contain" />
            </Column>
          </StyledSlide>
        </div>
      ))}
    </StyledSlider>
  );
};
export default VirtualWalkThrough;
