import React from "react";
import { salesforce } from "../../../../defaults";
import { ButtonTypes } from "components/Button/types";
import {
  FormContainer,
  FormGroup,
  FormTitle,
  FormRow,
  FormTextInput,
  FormTextArea,
  FormLegend,
  FormSubmitButton,
  Title,
  FormParagraph,
} from "../styles";
import PolicyCheckbox from "./PolicyCheckbox";
import { POWER_PROJECT_FORM_FIELDS } from "./constants";
import { REGEX_VALIDATION_PATTERNS } from "consts/validations";

const { webToLead } = salesforce;

const PowerForm = ({ retURL, formSubmitCallback }) => {
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formElem = event.target;
    formElem.method = "POST";
    formElem.action = webToLead.uri;
    formElem.target = "sf-power-project";

    try {
      formElem.submit();
    } catch (err) {
      console.error(err);
    } finally {
      return new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
        formSubmitCallback()
      );
    }
  };

  return (
    <FormContainer id="power-project-form" onSubmit={handleFormSubmit}>
      <input
        id="recordType"
        name="recordType"
        value={webToLead.powerProject.recordType}
        type="hidden"
      />
      <input
        id="oid"
        name="oid"
        value={webToLead.powerProject.oid}
        type="hidden"
      />
      <input
        id={webToLead.powerProject.project_name.id}
        name={webToLead.powerProject.project_name.id}
        value={webToLead.powerProject.project_name.value}
        type="hidden"
      />
      <input name="retURL" value={retURL} type="hidden" />
      <label hidden htmlFor="lead_source">
        Lead Source
      </label>
      <select hidden id="lead_source" name="lead_source">
        <option value={`Savant Website | ${retURL}`}>
          Savant Website | {String(retURL)}
        </option>
      </select>
      <FormGroup className="text-input-fields">
        <FormTitle>Start a power project</FormTitle>
        <Title>CONTACT US</Title>
        <FormParagraph>
          If you are interested in learning more about the Savant Power System,
          but not sure how to get started, complete the contact form below and a
          Savant Representative will be in touch to help you make the best
          choices.
        </FormParagraph>
        <FormRow className="flex-row">
          <FormTextInput
            placeholder="First Name*"
            id={POWER_PROJECT_FORM_FIELDS.FIRST_NAME}
            name={POWER_PROJECT_FORM_FIELDS.FIRST_NAME}
            required={true}
          />
          <FormTextInput
            placeholder="Last Name*"
            id={POWER_PROJECT_FORM_FIELDS.LAST_NAME}
            name={POWER_PROJECT_FORM_FIELDS.LAST_NAME}
            required={true}
          />
        </FormRow>
        <FormRow>
          <FormTextInput
            placeholder="Email Address*"
            type="email"
            pattern={REGEX_VALIDATION_PATTERNS.EMAIL}
            id={POWER_PROJECT_FORM_FIELDS.EMAIL_ADDRESS}
            name={POWER_PROJECT_FORM_FIELDS.EMAIL_ADDRESS}
            required={true}
          />
        </FormRow>
        <FormRow>
          <FormTextInput
            placeholder="Phone"
            id={POWER_PROJECT_FORM_FIELDS.PHONE}
            name={POWER_PROJECT_FORM_FIELDS.PHONE}
            required={false}
          />
        </FormRow>
        <FormRow>
          <FormTextArea
            placeholder="Project Description"
            id={POWER_PROJECT_FORM_FIELDS.PROJECT_DESCRIPTION}
            name={POWER_PROJECT_FORM_FIELDS.PROJECT_DESCRIPTION}
            required={false}
          />
        </FormRow>
        <FormLegend>*Required</FormLegend>
      </FormGroup>
      <FormGroup className="policy-agreement-field">
        <PolicyCheckbox
          name={POWER_PROJECT_FORM_FIELDS.POLICY_AGREEMENT}
          id={POWER_PROJECT_FORM_FIELDS.POLICY_AGREEMENT}
          defaultValue={false}
          required={true}
        />
      </FormGroup>
      <FormSubmitButton type={ButtonTypes.ORANGE} htmlType="submit">
        Submit
      </FormSubmitButton>
    </FormContainer>
  );
};

export default PowerForm;
