import React from "react";
import {
  Container,
  Section,
  BackgroundImage,
  Column,
  FormTitle,
  BackButton,
} from "./styles";
import { ButtonTypes } from "components/Button/types";

const SubmitSuccessModal = ({
  onCloseHandler,
  title = "Thank You",
  image,
  children,
  btnLabel = "Back",
}) => {
  if (image) {
    return (
      <Container>
        <Section>
          <Column className="text">
            <FormTitle>{title}</FormTitle>
            {children}
          </Column>
          <Column className="back-button">
            <BackButton
              type={ButtonTypes.ORANGE}
              htmlType="button"
              onClick={() => onCloseHandler()}
            >
              {btnLabel}
            </BackButton>
          </Column>
        </Section>
        <Section className="image-section">
          <BackgroundImage item={{ publicURL: image }} objectFit="cover" />
        </Section>
      </Container>
    );
  } else {
    return (
      <>
        <FormTitle>{title}</FormTitle>
        {children}
        <BackButton onClick={() => onCloseHandler()}>{btnLabel}</BackButton>
      </>
    );
  }
};

export default SubmitSuccessModal;
