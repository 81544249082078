import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "../../../components/Theme/default-theme";

export const RowItem = styled.div`
  background: ${({ isOdd }) => (isOdd ? "white" : "#DBD9D6")};
  display: flex;
  min-height: 48px;
`;

export const RowsItemColumn = styled(ReactMarkdown)`
  flex: ${({ flexNum }) => flexNum};
  padding: 10px 8px;
  color: #696057;
  & + & {
    border-left: 1px solid rgba(241, 239, 235);
  }

  &:last-child {
    display: none;
  }

  text-align: ${({ center }) => (center ? "center" : "left")};
`;

export const Container = styled.div`
  ${media.tablet} {
    ${RowsItemColumn} {
      display: initial;
    }
  }
`;

export const TableHeader = styled.div`
  background: #dbd9d6;
  display: flex;
`;

export const TableHeaderItem = styled(RowsItemColumn)`
  & + & {
    border: none;
  }

  padding-top: 40px;
  ${media.tablet} {
    padding-top: 0;
  }
`;
