import styled from "styled-components";

export const ItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

export const ItemUL = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-inline-start: 0;
`;

export const Container = styled.div`
  margin: 3% 14%;
`;
