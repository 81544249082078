import { graphql } from "gatsby";
import React from "react";
import Layout from "components/Layout";
import PropTypes from "prop-types";
import { sectionToWidgetMapper } from "../utils/sectionToWidgetMapper";

export default function ConstructorPage({
  data: { markdownRemark: data = {} } = {},
  path,
}) {
  const sections = data?.frontmatter?.sections || [];

  return (
    <Layout>
      <React.Fragment>
        {sections.map((section, index) =>
          sectionToWidgetMapper(section, index, path)
        )}
      </React.Fragment>
    </Layout>
  );
}

ConstructorPage.propTypes = {
  data: PropTypes.shape({}),
};

export const query = graphql`
  query SavConstructorPageQuery($slug: String!, $uid: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug }, uid: { eq: $uid } }) {
      id
      frontmatter {
        title
        uid
        slug
        sections {
          ...CommonSectionFieldsFragment
          ...LeftRightBlocksFragment
          ...ProductGalleryFragment
          ...HeroImageFragment
          ...TextBlockFragment
          ...TitleImgTextFragment
          ...SeeMoreCaseStudiesFragment
          ...PhotoGalleryFragment
          ...DeviceCenteredFragment
          ...FourImageWithLabelsFragment
          ...ImageLeftTextRightFragment
          ...ThreeColumnGridWithLabelsFragment
          ...VirtualWalkThroughFragment
          ...VaryDeviceImageFragment
          ...LargeSpecificationFragment
          ...DevicesThreeAcrossFragment
          ...HeroVideoFragment
          ...AppShotStaggeredFragmentFragment
          ...ProductServiceHighlightFragment
          ...ImageCustomGridFragment
          ...ProductGridFragment
          ...JumpNavigationFragment
          ...IconAndTextGridFragment
          ...ImageTilesFragment
          ...NewsTilesFragment
          ...ArticleFragment
          ...TwoColumnIconsAndTextFragment
          ...HeroBackgroundFragment
          ...ResourcesContentFragment
          ...ListWithIconsFragment
          ...InteractiveSelectFragment
          ...HeroImageSlideshowFragment
          ...MusicPlaylistFragment
          ...TwoColumnLeftAlignedIconWithTextFragment
          ...SavantTimelineFragment
        }
      }
    }
  }
`;
