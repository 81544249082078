import styled from "styled-components";
import Select from "react-select";

export const SelectCustomStyles = {
  container: (provided) => ({
    ...provided,
    width: "100%",
    lineHeight: "24px",
    border: "1px solid rgba(56, 46, 43, 0.4)",
  }),
  control: (provided, { isDisabled }) => ({
    ...provided,
    height: "40px",
    background: "transparent",
    fontFamily: "Gotham, sans-serif",
    border: "none",
    borderRadius: 0,
    boxShadow: "none",
    pointerEvents: "auto",
    opacity: isDisabled ? 0.55 : 1,
    "&:hover": {
      cursor: isDisabled ? "not-allowed" : "default",
    },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({ ...provided, color: "#382E2B" }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Gotham, sans-serif",
    lineHeight: "24px",
    fontWeight: "325",
    color: "#382E2B",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Gotham, sans-serif",
    lineHeight: "24px",
    color: "#382E2B",
    fontWeight: "325",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 8px 2px 13px",
  }),
};

export const StyledSelect = styled(Select)``;
