import React from "react";
import { Container, Logotype, Background } from "./styles";

const HeroBackground = ({ image, logotype, logotypePosition = "center" }) => {
  return (
    <Container>
      {logotype && <Logotype item={logotype} position={logotypePosition} />}
      <Background alt="" item={image} objectFit="cover" />
    </Container>
  );
};

export default HeroBackground;
