import styled from "styled-components";
import Slider from "react-slick";

export const Container = styled.div`
  padding-top: 275px;
`;

export const PagingItem = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  background: #f2f2f2;
`;

export const StyledSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > li {
      width: 10px;
      height: 10px;
    }

    > li.slick-active {
      ${PagingItem} {
        border: 1px solid #ff5f00;
        background: white;
      }
    }
  }

  .slick-prev {
    left: 3% !important;
  }

  .slick-next {
    right: 3% !important;
  }
`;
