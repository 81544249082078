import React from "react";
import {
  ArticleContentContainer,
  ArticleItemSubtitle,
  ArticleItemContainer,
  ArticleItemDate,
  ArticleItemImage,
  ArticleItemLink,
  ArticleItemTitle,
} from "./styles";

const ArticleContent = ({ data }) => {
  return (
    <ArticleContentContainer>
      {data.map(
        ({ startDate, endDate, title, subTitle, linkUrl, image }, idx) => {
          return (
            <ArticleItemContainer key={`${startDate}-${title}-${idx}`}>
              {image && <ArticleItemImage item={image} objectFit="contain" />}
              <ArticleItemDate>
                {startDate}
                {endDate && endDate !== "Invalid date" && ` - ${endDate}`}
              </ArticleItemDate>
              <ArticleItemTitle>{title}</ArticleItemTitle>
              {subTitle?.length > 0 && (
                <ArticleItemSubtitle>{subTitle}</ArticleItemSubtitle>
              )}

              <ArticleItemLink to={linkUrl}>Read More</ArticleItemLink>
            </ArticleItemContainer>
          );
        }
      )}
    </ArticleContentContainer>
  );
};

export default ArticleContent;
