import styled, { keyframes } from "styled-components";
import Image from "../../../components/Image";
import { media } from "../../../components/Theme/default-theme";

const ScaleUpAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
`;

const ScaleDownAnimation = keyframes`
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

export const SelectedItemIndicator = styled.div`
  position: relative;
  top: 50px;
  left: 40%;
  height: 20px;
  width: 20px;
  background: #f2f2f2;
  border-radius: 10px;
`;

export const StyledImage = styled(Image)`
  flex: 1 1 auto;
  max-width: 100px;
  animation-name: ${({ active }) =>
    active ? ScaleUpAnimation : ScaleDownAnimation};
  animation-duration: 800ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-direction: normal;

  ${media.tablet} {
    max-width: 100px;
  }

  ${media.laptop} {
    max-width: 125px;
  }

  ${media.desktop} {
    max-width: 160px;
  }
`;

export const Container = styled.div`
  &:hover ${StyledImage} {
    animation-name: ${ScaleUpAnimation};
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-direction: normal;
  }
`;
