import React from "react";
import {
  Container,
  Item,
  ItemImage,
  ItemLinkIcon,
  ItemLabelWrapper,
  ItemLabel,
  ItemsWrapper,
  SectionTitle,
} from "./styles";
import useCurrentDevice from "../../utils/useCurrentDevice";
import { Devices } from "../../consts/devices";

const ImageTiles = ({ sectionTitle, items }) => {
  const currentDevice = useCurrentDevice();
  const isMobileOrTable = [Devices.MOBILE, Devices.TABLET].includes(
    currentDevice
  );
  return (
    <Container>
      <SectionTitle
        data-aos="fade-in"
        data-aos-duration="1000"
        data-aos-easing="ease-in"
        data-aos-once="true"
      >
        {sectionTitle}
      </SectionTitle>
      <ItemsWrapper>
        {items.map((item, index) => {
          return (
            <Item
              key={`${item.label}-${index}`}
              data-aos="fade-in"
              data-aos-offset={0}
              data-aos-delay={isMobileOrTable ? 200 : ((index + 1) % 7) * 200}
              data-aos-duration="300"
              data-aos-easing="ease-in"
              data-aos-once="true"
              to={item.linkUrl}
            >
              <ItemImage item={item.image} />
              <ItemLabelWrapper>
                <ItemLabel>{item.label}</ItemLabel>
                <ItemLinkIcon type={"ChevronRight"} />
              </ItemLabelWrapper>
            </Item>
          );
        })}
      </ItemsWrapper>
    </Container>
  );
};

export default ImageTiles;
