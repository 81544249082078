import React from "react";
import {
  Container,
  StyledInnerContainer,
  StyledTitle,
  StyledFullScreenImage,
  StyledImage,
  StyledParagraph,
  Column,
  Content,
  StyledLink,
} from "./styles";

const FullBackgroundVaryDevice = ({
  title,
  paragraph,
  image,
  backgroundImage,
  btnUrl,
  reverseMode,
}) => {
  const imageColumn = (
    <Column>
      <StyledImage reverseMode={reverseMode} item={image} objectFit="contain" />
    </Column>
  );

  const textColumn = (
    <Column>
      <Content>
        <StyledTitle>{title}</StyledTitle>
        <StyledParagraph>{paragraph}</StyledParagraph>
        {btnUrl && <StyledLink to={btnUrl}>learn more</StyledLink>}
      </Content>
    </Column>
  );

  return (
    <Container>
      <StyledFullScreenImage item={backgroundImage} />
      <StyledInnerContainer
        data-aos="fade"
        data-aos-offset="300"
        data-aos-duration="500"
        data-aos-once="true"
      >
        {reverseMode ? (
          <>
            {textColumn}
            {imageColumn}
          </>
        ) : (
          <>
            {imageColumn}
            {textColumn}
          </>
        )}
      </StyledInnerContainer>
    </Container>
  );
};
export default FullBackgroundVaryDevice;
