import React from "react";
import { Container, Header2, StyledImage, Paragraph } from "./styles";
import useCurrentDevice from "../../utils/useCurrentDevice";
import { Devices } from "../../consts/devices";

const MobileAnimationProps = {
  type: "fade",
  easing: "ease-out",
  delay: "1",
  duration: "300",
  once: "true",
};

const AnimationProps = {
  type: "fade-down",
  easing: "ease-out",
  delay: "1",
  duration: "500",
  once: "true",
};

const TitleImgTextBlock = ({ imageField, header, textField }) => {
  const currentDevice = useCurrentDevice();
  const isMobile = [Devices.MOBILE].includes(currentDevice);
  const { type, easing, delay, duration, once } = isMobile
    ? MobileAnimationProps
    : AnimationProps;

  return (
    <Container
      data-aos={type}
      data-aos-easing={easing}
      data-aos-delay={delay}
      data-aos-duration={duration}
      data-aos-once={once}
    >
      <Header2>{header}</Header2>
      {imageField && <StyledImage item={imageField} alt="" />}
      <Paragraph>{textField}</Paragraph>
    </Container>
  );
};

export default TitleImgTextBlock;
