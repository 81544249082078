import React from "react";
import {
  FlexRow,
  DescriptionContainer,
  IconContainer,
  StyledIcon,
  StyledDescription,
} from "./styles";

const TCLAItem = ({ icon, description }) => {
  return (
    <FlexRow
      data-aos="fade-in"
      data-aos-offset="300"
      data-aos-easing="ease-out"
      data-aos-duration="500"
      data-aos-once="true"
    >
      <IconContainer>
        <StyledIcon item={icon} objectFit="contain" />
      </IconContainer>
      <DescriptionContainer>
        <StyledDescription>{description}</StyledDescription>
      </DescriptionContainer>
    </FlexRow>
  );
};

export default TCLAItem;
