import React from "react";
import {
  Container,
  GalleryImageWrapper,
  GalleryImage,
  Header2,
  ImagesWrapper,
  Paragraph,
} from "./styles";
import WithAnimation from "../../utils/withAnimation";
import { Devices } from "../../consts/devices";
import PhotoGalleryPreview, {
  usePhotoGalleryPreview,
} from "../PhotoGalleryPreview";

const PhotoGallery = ({ header, galleryImages, paragraph }) => {
  const { initialSlide, handleClickBySlideIndex, onClose } =
    usePhotoGalleryPreview();

  return (
    <Container>
      <WithAnimation
        animations={[
          {
            animationAttributes: {
              "data-aos": "fade-down",
              "data-aos-delay": "400",
              "data-aos-offset": "200",
              "data-aos-duration": "500",
              "data-aos-easing": "ease-in",
              "data-aos-once": "true",
            },
            devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
          },
        ]}
      >
        <div>
          <WithAnimation
            animations={[
              {
                animationAttributes: {
                  "data-aos": "fade",
                  "data-aos-delay": "1",
                  "data-aos-duration": "300",
                  "data-aos-once": "true",
                },
                devices: [Devices.MOBILE],
              },
            ]}
          >
            <Header2>{header}</Header2>
          </WithAnimation>
          <ImagesWrapper>
            {galleryImages.map((item, index) => (
              <WithAnimation
                key={"item-" + index}
                animations={[
                  {
                    animationAttributes: {
                      "data-aos": "fade",
                      "data-aos-delay": "1",
                      "data-aos-duration": "300",
                      "data-aos-once": "true",
                    },
                    devices: [Devices.MOBILE],
                  },
                ]}
              >
                <GalleryImageWrapper onClick={handleClickBySlideIndex(index)}>
                  <GalleryImage item={item} />
                </GalleryImageWrapper>
              </WithAnimation>
            ))}
          </ImagesWrapper>
          {paragraph && <Paragraph>{paragraph}</Paragraph>}
        </div>
      </WithAnimation>
      <PhotoGalleryPreview
        images={galleryImages}
        initialSlide={initialSlide}
        onClose={onClose}
      />
    </Container>
  );
};

export default PhotoGallery;
