import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const ProductImage = styled(Image)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: 0;

  & img {
    ${({ another }) =>
      (another === "true" || another === true) &&
      css`
        width: 95% !important;
        object-position: right !important;
      `}
  }

  ${media.tablet} {
    & img {
      ${({ another }) =>
        (another === "true" || another === true) && "85% !important;"}
    }
  }
`;

export const ProductDescription = styled(ReactMarkdown)`
  p {
    font-size: clamp(0.9375rem, 0.864rem + 0.3676vw, 1.25rem);
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 150%;
    color: #382e2b;
  }
`;

export const ProductLabel = styled.h3`
  margin: 0;
  font-size: clamp(1rem, 0.8235rem + 0.8824vw, 1.75rem);
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  color: #382e2b;
`;

export const Content = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  float: right;
  padding-right: 9.5%;
`;

export const ProductUrl = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  text-decoration-line: underline;
  color: #382e2b;
  margin-top: 15px;

  &:hover {
    color: #ff5f00;
    cursor: pointer;
  }
`;

export const ProductGridItem = styled.div`
  background: ${({ isAnother }) => (isAnother ? "#F8F8F8" : "")};
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 260px;
`;

export const Grid = styled.div`
  display: grid;
  margin: 30px;
  grid-gap: 30px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
    margin: 40px;

    ${ContentWrapper} {
      height: 40vw;
    }

    ${ProductUrl} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  ${media.laptop} {
    margin: 20px;
    grid-gap: 20px;

    ${ContentWrapper} {
      height: 550px;
    }

    ${ProductUrl} {
      font-size: 20px;
      line-height: 30px;
    }
  }

  ${media.desktop} {
    max-width: 1310px;
    margin: 50px auto;
    grid-gap: 30px;

    ${ContentWrapper} {
      height: 460px;
    }
  }
`;
