import styled, { css } from "styled-components";
import Slider from "react-slick";
import { media } from "../../components/Theme/default-theme";
import ImageComponent from "components/Image";

const BaseFontStyles = css`
  font-family: Gotham, sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 325;
`;

export const Container = styled.div`
  position: relative;
  padding: 60px 0 50px;
  display: flex;
  flex: 1;
  flex-direction: column;

  ${media.desktop} {
    background: #f8f8f8;
  }
`;

export const GalleryHeader = styled.h2`
  ${BaseFontStyles}
  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  line-height: 125%;
  color: #382e2b;
  text-transform: uppercase;
  margin: 0;
`;

export const ProductItem = styled.div`
  display: flex !important;
  outline: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  height: 100%;

  ${media.laptop} {
    padding: 80px 0;
  }
`;

export const ProductImage = styled(ImageComponent)`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 35rem;
`;

export const ProductTitle = styled.p`
  ${BaseFontStyles}
  font-size: clamp(0.875rem, 0.7251rem + 0.4996vw, 1.25rem);
  line-height: 125%;
  color: #382e2b;
`;

export const PagingItem = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  background: #f2f2f2;
`;

export const StyledSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 1;
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    > li {
      width: 10px;
      height: 10px;
    }

    > li.slick-active {
      ${PagingItem} {
        border: 1px solid #ff5f00;
        background: white;
      }
    }
  }

  .slick-prev {
    left: 3% !important;
  }

  .slick-next {
    right: 3% !important;
  }
`;
