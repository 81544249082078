import React from "react";
import { Container, Content, StyledImage, Label } from "./styles";

const FourImagesInRow = ({ columns }) => {
  return (
    <Container>
      {columns.map(({ image, label = "" }, index) => (
        <Content key={`4imgrow-${index}-${label}`}>
          <StyledImage item={image} alt={label} objectFit="cover" />
          <Label>{label}</Label>
        </Content>
      ))}
    </Container>
  );
};

export default FourImagesInRow;
