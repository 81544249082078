/** A whitelist used to gate sources for utm source query string params */
const utm_whitelist = new Set(["nativo", "google", "nanwine", "figwai"]);

/**
 * @typedef SearchParamsResult
 * @property {string} dealerID
 * @property {string} homeID
 * @property {string} referralCode
 * @property {string?} utm_source
 * @property {string?} utm_medium
 * @property {string?} utm_content
 * @property {string?} utm_campaign
 */

/**
 * @description Checks the url query string for relevant information and returns the results
 * @param {URLSearchParams} searchParams
 * @returns {SearchParamsResult}
 * */
export function checkGetTargetSearchParams(searchParams) {
  // Values that are always expected in the payload
  const dealerID = searchParams?.get("d") ?? "";
  const homeID = searchParams?.get("homeID") ?? "";
  const referralCode = searchParams?.get("ReferralCode") ?? "";
  const utm_source = searchParams?.get("utm_source") ?? "";

  let returnParams = {
    utm_content: "",
    utm_medium: "",
    utm_campaign: "",
    dealerID,
    homeID,
    referralCode,
    utm_source,
  };

  // Update related UTM fields when a valid UTM source is given
  if (utm_whitelist.has(utm_source)) {
    const utm_content = searchParams?.get("utm_content") ?? "";
    const utm_medium = searchParams?.get("utm_medium") ?? "";
    const utm_campaign = searchParams?.get("utm_campaign") ?? "";

    returnParams = {
      ...returnParams,
      utm_content,
      utm_medium,
      utm_campaign,
    };
  }

  return returnParams;
}

export const makeQuery = (params) => {
  return Object.entries(params)
    .reduce((prev, [key, value]) => {
      const field = `${key}=${
        typeof value === "object" ? value.join(",") : value
      }`;
      return [...prev, field];
    }, [])
    .join("&");
};
