import styled, { css } from "styled-components";

export const CloseIconWrapper = styled.div`
  position: absolute;
  top: 14px;
  ${({ close_align }) =>
    close_align === "left" ? "left: 14px;" : "right: 14px;"}

  &:hover {
    cursor: pointer;
  }
`;
export const ModalBody = styled.div`
  position: relative;
  height: 100%;
  overflow: scroll;
`;

export const ModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      display: none;
    `}
  background: #fff;
  z-index: 9999;
  top: 0;
  left: 0;
`;
