import React from "react";
import {
  Column,
  Container,
  StyledTitle,
  StyledText,
  Content,
  StyledIcon,
  StyledIconWrapper,
  StyledButtonWrapper,
} from "./styles";
import Button from "components/Button";
import WithAnimation from "../../utils/withAnimation";
import { Devices } from "../../consts/devices";

const LeftRightBlocks = ({
  leftIconType,
  leftTitle,
  leftSubtitle,
  leftButtonLabel,
  leftButtonHref,
  leftImage,
  rightIconType,
  rightTitle,
  rightSubtitle,
  rightButtonLabel,
  rightButtonHref,
  rightImage,
  contentAlign,
}) => {
  return (
    <Container>
      <Column image={leftImage?.publicURL ?? ""}>
        <WithAnimation
          animations={[
            {
              animationAttributes: {
                "data-aos": "fade-in",
                "data-aos-easing": "ease-in",
                "data-aos-once": "true",
              },
              devices: [Devices.MOBILE],
            },
          ]}
        >
          <Content>
            <StyledIconWrapper>
              <StyledIcon type={leftIconType} />
            </StyledIconWrapper>
            <StyledTitle contentAlign={contentAlign}>{leftTitle}</StyledTitle>
            <StyledText contentAlign={contentAlign}>{leftSubtitle}</StyledText>
            {leftButtonLabel && leftButtonHref && (
              <StyledButtonWrapper contentAlign={contentAlign}>
                <Button type="dune" href={leftButtonHref}>
                  {leftButtonLabel}
                </Button>
              </StyledButtonWrapper>
            )}
          </Content>
        </WithAnimation>
      </Column>
      <Column image={rightImage?.publicURL ?? ""} grey>
        <WithAnimation
          animations={[
            {
              animationAttributes: {
                "data-aos": "fade-in",
                "data-aos-easing": "ease-in",
                "data-aos-once": "true",
              },
              devices: [Devices.MOBILE],
            },
          ]}
        >
          <Content>
            <StyledIconWrapper>
              <StyledIcon type={rightIconType} />
            </StyledIconWrapper>
            <StyledTitle contentAlign={contentAlign}>{rightTitle}</StyledTitle>
            <StyledText contentAlign={contentAlign}>{rightSubtitle}</StyledText>
            {rightButtonLabel && rightButtonHref && (
              <StyledButtonWrapper contentAlign={contentAlign}>
                <Button type="dune" href={rightButtonHref}>
                  {rightButtonLabel}
                </Button>
              </StyledButtonWrapper>
            )}
          </Content>
        </WithAnimation>
      </Column>
    </Container>
  );
};

export default LeftRightBlocks;
