import styled, { css } from "styled-components";
import Button from "components/Button";
import { media } from "../../components/Theme/default-theme";
import Input from "components/Input";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 50px auto 70px;
  padding: 0 20px;

  ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
    margin-bottom: 90px;
    width: 95%;
  }

  ${media.laptop} {
    margin-top: 100px;
    margin-bottom: 120px;
    width: 80%;
  }
`;

export const Content = styled.div`
  max-width: 350px;
  margin-bottom: 25px;

  ${media.tablet} {
    max-width: 300px;
    margin-bottom: 0;
  }

  ${media.laptop} {
    max-width: 350px;
  }

  ${media.desktop} {
    max-width: 403px;
  }
`;

export const StyledTitle = styled.h3`
  margin: 0 0 25px;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #382e2b;

  ${media.laptop} {
    font-size: 28px;
    line-height: 35px;
  }
`;

export const StyledParagraph = styled.p`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #382e2b;

  ${media.tablet} {
    font-size: 16px;
    line-height: 24px;
  }

  ${media.laptop} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const StyledForm = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    width: 58%;
  }
`;

export const StyledFormGroup = styled.div`
  width: 100%;
  margin: 0 15px 15px 0;

  ${media.laptop} {
    width: 50%;
  }
`;

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: column;

  ${media.laptop} {
    flex-direction: row;
  }
`;

export const StyledInput = styled(Input)``;

export const StyledErrorMessage = styled.div`
  margin-top: 2px;
  font-family: Gotham, sans-serif;
  font-size: 14px;
  color: red;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  padding-top: 17px;
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 19px;

  ${media.tablet} {
    margin-top: 5px;
  }

  ${media.laptop} {
    width: 201px;
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const requiredStyles = css`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ff6000;
`;

export const StyledRequiredMobile = styled.div`
  ${requiredStyles}

  ${media.laptop} {
    display: none;
  }
`;

export const StyledRequiredLaptop = styled.div`
  ${requiredStyles};
  display: none;

  ${media.laptop} {
    display: block;
    position: absolute;
    left: 50%;
    bottom: 90px;
  }

  ${media.desktop} {
    bottom: 73px;
  }
`;
