import { Devices } from "../../consts/devices";

export const PageAnimationOptions = [
  {
    animationAttributes: {
      "data-aos": "fade",
      "data-aos-offset": "200",
      "data-aos-delay": "100",
      "data-aos-easing": "ease-out",
      "data-aos-duration": "800",
      "data-aos-once": "true",
    },
    devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
  },
];

export const BackgroundImageAnimationOptions = [
  {
    animationAttributes: {
      "data-aos": "fade-down",
      "data-aos-offset": "40",
      "data-aos-easing": "ease-in-sine",
      "data-aos-delay": "200",
      "data-aos-once": "true",
    },
    devices: [Devices.MOBILE],
  },
];

export const InteractiveNavigatorAnimations = [
  {
    animationAttributes: {
      "data-aos": "fade-in",
      "data-aos-offset": "80",
      "data-aos-easing": "ease-out",
      "data-aos-duration": "800",
      "data-aos-once": "true",
    },
    devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
  },
  {
    animationAttributes: {
      "data-aos": "fade-in",
      "data-aos-offset": "40",
      "data-aos-easing": "ease-in-sine",
      "data-aos-delay": "200",
      "data-aos-once": "true",
    },
    devices: [Devices.MOBILE],
  },
];

export const InteractiveContentAnimation = [
  {
    animationAttributes: {
      "data-aos": "fade-in",
      "data-aos-easing": "ease-in-out",
      "data-aos-duration": "1000",
      "data-aos-once": "true",
    },
    devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
  },
];
