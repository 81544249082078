import React from "react";
import SliderArrow from "components/SliderArrow";
import {
  Container,
  DeviceImage,
  DeviceItem,
  DeviceLabel,
  DevicesWrapper,
  StyledSlider,
} from "./styles";
import WithAnimation from "../../utils/withAnimation";
import { Devices } from "../../consts/devices";
import defaultTheme from "../../components/Theme/default-theme";
import SavantAmbassador from "../../../static/icons/SavantAmbassador.svg";

const DevicesThreeAcross = ({ deviceItems = [] }) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SliderArrow />,
    prevArrow: <SliderArrow isPrev />,
    responsive: [
      {
        breakpoint: defaultTheme.size.laptop - 1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: defaultTheme.size.tablet - 1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  let devices = deviceItems || [];
  if (devices.length < 1) {
    devices.push({
      deviceLabel: "Please add items to DevicesThreeAcross",
      deviceImage: SavantAmbassador,
    });
  }

  return (
    <Container>
      <DevicesWrapper>
        <StyledSlider {...settings}>
          {devices.map(({ deviceImage, deviceLabel }, index) => (
            <WithAnimation
              key={deviceLabel + index}
              animations={[
                {
                  animationAttributes: {
                    "data-aos": "fade-in",
                    "data-aos-offset": "300",
                    "data-aos-delay": index * 300,
                    "data-aos-easing": "ease-in",
                    "data-aos-duration": "500",
                    "data-aos-once": "true",
                  },
                  devices: [
                    Devices.DESKTOP,
                    Devices.LAPTOP,
                    Devices.TABLET,
                    Devices.MOBILE,
                  ],
                },
              ]}
            >
              <DeviceItem>
                <DeviceImage item={deviceImage} alt="device" />
                <DeviceLabel>{deviceLabel}</DeviceLabel>
              </DeviceItem>
            </WithAnimation>
          ))}
        </StyledSlider>
      </DevicesWrapper>
    </Container>
  );
};

export default DevicesThreeAcross;
