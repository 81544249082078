import { Element } from "react-scroll";
import React from "react";
import { WidgetsMap } from "../consts/WidgetsMap";

export const sectionToWidgetMapper = (section, index, path) => {
  const { type: widgetType, anchorId } = section;
  const WidgetComponent = WidgetsMap[widgetType];

  if (!WidgetComponent) {
    console.error(`Provide widget component into MAP for "${widgetType}" type`);
  }

  return (
    <div key={index}>
      {anchorId && <Element name={anchorId} />}
      <WidgetComponent {...section} path={path} />
    </div>
  );
};
