import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Image from "components/Image";
import LearnMoreBtn from "components/LearnMoreBtn";
import { media } from "../../components/Theme/default-theme";

export const ItemsWrapper = styled.div``;

export const Item = styled.div`
  border: 1px solid #dbd9d6;
  box-sizing: border-box;
  padding: 30px 20px;

  &:first-child {
    margin-right: -1px;
  }
`;

export const ItemImage = styled(Image)``;

export const ItemTextWrapper = styled.div``;

export const BtnLink = styled(LearnMoreBtn)`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #ff5f00;
  text-decoration: none;
  text-transform: initial;
  margin: 0;
`;

export const ItemTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #666;
  margin: 20px 0 0;
`;

export const ItemSubtitle = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #666;
  }
`;

export const Container = styled.div`
  background: #f2f2f2;

  ${media.tablet} {
    ${ItemsWrapper} {
      display: flex;

      ${Item} {
        flex: 1;
        padding: 40px 30px;
      }
    }
  }

  ${media.desktop} {
    ${ItemsWrapper} {
      ${Item} {
        display: flex;
        padding: 37px 112px;

        ${ItemTextWrapper} {
          margin-left: 33px;

          ${ItemTitle} {
            margin: 0;
          }

          ${ItemSubtitle} p {
            margin: 4px 0 6px;
          }
        }
      }
    }
  }
`;
