import React from "react";
import {
  Container,
  SpecificationImage,
  SpecificationItem,
  SpecificationSubtitle,
  SpecificationTitle,
} from "./styles";
import WithAnimation from "../../utils/withAnimation";
import { Devices } from "../../consts/devices";

const LargeSpecifications = ({ specificationItems }) => {
  return (
    <WithAnimation
      animations={[
        {
          animationAttributes: {
            "data-aos": "fade",
            "data-aos-offset": "40",
            "data-aos-easing": "ease-in",
            "data-aos-duration": "600",
            "data-aos-delay": "400",
            "data-aos-once": "true",
          },
          devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
        },
      ]}
    >
      <Container>
        {specificationItems.map(
          ({ title, subtitle, specificationImage }, index) => (
            <WithAnimation
              key={"specification-item-" + index}
              animations={[
                {
                  animationAttributes: {
                    "data-aos": "fade",
                    "data-aos-offset": "200",
                    "data-aos-easing": "ease-in",
                    "data-aos-duration": "600",
                    "data-aos-delay": "300",
                    "data-aos-once": "true",
                  },
                  devices: [Devices.MOBILE],
                },
              ]}
            >
              <SpecificationItem key={title + index}>
                {specificationImage && (
                  <SpecificationImage item={specificationImage} />
                )}
                <SpecificationTitle>{title}</SpecificationTitle>
                <SpecificationSubtitle>{subtitle}</SpecificationSubtitle>
              </SpecificationItem>
            </WithAnimation>
          )
        )}
      </Container>
    </WithAnimation>
  );
};

export default LargeSpecifications;
