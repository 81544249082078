import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";
import Link from "gatsby-link";

export const Grid = styled.div``;

export const GridRow = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GridItem = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  transition: background-color 100ms ease-in;
  text-decoration: none;

  &:hover {
    background-color: #ededed;
  }
`;

export const StyledImage = styled(Image)`
  min-width: 50px;
  min-height: 50px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const Title = styled.h3`
  margin: 16px 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000;
`;

export const Subtitle = styled(ReactMarkdown)`
  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #696057;
  }
`;

export const Container = styled.div`
  ${media.tablet} {
    ${GridRow} {
      flex-direction: row;

      ${GridItem} {
        width: 50%;
        min-height: 250px;
      }
    }
  }

  ${media.laptop} {
    ${GridRow} {
      ${GridItem} {
        min-height: 350px;
        flex-direction: row;

        ${StyledImage} {
          min-width: 70px;
          min-height: 70px;
        }

        ${TextWrapper} {
          margin-left: 38px;
          width: 400px;
          align-items: flex-start;
          text-align: left;

          ${Title} {
            font-size: 22px;
            line-height: 60px;
            margin: 0;
          }

          ${Subtitle} p {
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }
  }
`;
