import React from "react";
import { CloseIconWrapper, ModalBody, ModalContainer } from "./styles";
import Icon from "../Icon";

const Modal = (props) => {
  const {
    isOpen,
    onClose,
    children,
    className,
    closeIcon = null,
    closeAlign = "right",
  } = props;
  return (
    <ModalContainer isOpen={isOpen} className={className}>
      <ModalBody>
        <CloseIconWrapper onClick={onClose} close_align={closeAlign}>
          {closeIcon ?? <Icon type={"HamburgerOpened"} />}
        </CloseIconWrapper>
        {children}
      </ModalBody>
    </ModalContainer>
  );
};

export default Modal;
