import React from "react";
import {
  Container,
  LeftSection,
  MarkdownBody,
  RightSection,
  StyledTable,
  StyledTableHeaderRows,
  StyledTableHeaderCell,
  StyledTableBodyRows,
  StyledTableBodyCell,
} from "./styles";
import ContactSavantLinks from "../../pageWidgets/sidebarWidgets/ContactSavantLinks";
import WorldwideHeadquarters from "../../pageWidgets/sidebarWidgets/WorldwideHeadquarters";
import QuickLinks from "../../pageWidgets/sidebarWidgets/QuickLinks";
import ViewFull from "../../components/ViewFull";

const CookieSection = ({ firstMarkdown, secondMarkdown, tableRowItems }) => {
  const [tableHeaderRows, ...tableBodyRows] = tableRowItems;

  return (
    <Container>
      <LeftSection>
        <ViewFull btnLabel="+ View the full Cookie Notice">
          <MarkdownBody>{firstMarkdown}</MarkdownBody>

          <StyledTable>
            <StyledTableHeaderRows>
              <StyledTableHeaderCell>
                {tableHeaderRows.firstCell}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell>
                {tableHeaderRows.secondCell}
              </StyledTableHeaderCell>
            </StyledTableHeaderRows>
            {tableBodyRows.map((item, index) => (
              <StyledTableBodyRows key={index}>
                <StyledTableBodyCell>{item.firstCell}</StyledTableBodyCell>
                <StyledTableBodyCell>{item.secondCell}</StyledTableBodyCell>
              </StyledTableBodyRows>
            ))}
          </StyledTable>

          <MarkdownBody>{secondMarkdown}</MarkdownBody>
        </ViewFull>
      </LeftSection>
      <RightSection>
        <QuickLinks />
        <ContactSavantLinks />
        <WorldwideHeadquarters />
      </RightSection>
    </Container>
  );
};

export default CookieSection;
