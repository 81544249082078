import styled from "styled-components";
import { media } from "components/Theme/default-theme";

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 50px 20px;

  ${media.tablet} {
    margin: 150px 50px;
  }

  ${media.laptop} {
    margin: 150px 300px;

    ${FlexContainer} {
      flex-direction: row;
    }
  }
`;
