import React, { useState } from "react";
import {
  Container,
  StyledBackgroundImageWrapper,
  StyledBackgroundImage,
  InteractiveItemsWrapper,
} from "./styles";
import WithAnimation from "../../utils/withAnimation";
import InteractiveItemNavigator from "./InteractiveItemNavigator";
import InteractiveItemContent from "./InteractiveItemContent";
import {
  BackgroundImageAnimationOptions,
  InteractiveNavigatorAnimations,
  PageAnimationOptions,
} from "./constants";
import useWindowSize from "../../utils/useWindowSize";
import defaultTheme from "../../components/Theme/default-theme";
import InteractiveMobilecomponent from "./InteractiveMobileComponent";

const InteractiveSelect = ({
  backgroundImage,
  interactiveItems,
  desktopLargeText,
}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(
    interactiveItems[selectedItemIndex]
  );

  const { width: windowWidth } = useWindowSize();

  const isMobile = windowWidth < defaultTheme.size.tablet;

  const handleItemClick = (item, idx) => {
    setSelectedItemIndex(idx);
    setSelectedItem(item);
  };

  return (
    <WithAnimation animations={PageAnimationOptions}>
      <Container desktopLargeText={desktopLargeText}>
        <WithAnimation animations={BackgroundImageAnimationOptions}>
          <StyledBackgroundImageWrapper>
            <StyledBackgroundImage
              item={backgroundImage}
              objectFit="cover"
              alt=""
            />
          </StyledBackgroundImageWrapper>
        </WithAnimation>
        {isMobile ? (
          <WithAnimation animations={InteractiveNavigatorAnimations}>
            <InteractiveMobilecomponent
              items={interactiveItems}
              largeText={desktopLargeText}
            />
          </WithAnimation>
        ) : (
          <>
            <WithAnimation animations={InteractiveNavigatorAnimations}>
              <InteractiveItemsWrapper>
                <InteractiveItemNavigator
                  items={interactiveItems}
                  clickHandler={handleItemClick}
                  selectedItemIndex={selectedItemIndex}
                />
              </InteractiveItemsWrapper>
            </WithAnimation>
            <InteractiveItemContent
              item={selectedItem}
              largeText={desktopLargeText}
            />
          </>
        )}
      </Container>
    </WithAnimation>
  );
};

export default InteractiveSelect;
