import React, { memo } from "react";
import { components } from "react-select";
import { SelectCustomStyles, StyledSelect } from "./styles";
import { IconMap } from "consts/icons";

const DropdownIndicator = memo((props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconMap.DropDown />
    </components.DropdownIndicator>
  );
});

const Select = React.forwardRef((props, ref) => (
  <StyledSelect
    {...props}
    ref={ref}
    styles={SelectCustomStyles}
    components={{ DropdownIndicator }}
  />
));

export default Select;
