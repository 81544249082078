import styled, { css } from "styled-components";
import LearnMoreBtn from "../LearnMoreBtn";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";

const ButtonStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  align-self: center;
  font-family: Gotham, sans-serif;
  font-size: clamp(0.6rem, 2vw, 1rem);
  line-height: 125%;
  font-weight: 325;
  text-align: center;
  margin: 52px 0;
`;

export const BackButton = styled(LearnMoreBtn)`
  ${ButtonStyle}
  width: clamp(7.125rem, 4.9518rem + 7.244vw, 12.5625rem) !important;
  height: auto;
`;

export const FormSubtitle = styled.h3`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(0.8rem, 2vw, 1.6rem);
  line-height: 150%;
  color: #382e2b;
  margin: clamp(0.3125rem, 0.0627rem + 0.8326vw, 0.9375rem) 0;
`;

export const FormTitle = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  font-size: clamp(0.8rem, 4vw, 2.5rem);
  line-height: 125%;
  color: #382e2b;
  margin-top: 130px;
  margin-bottom: clamp(0.9375rem, -0.9359rem + 6.2448vw, 5.625rem);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BackgroundImage = styled(Image)``;

export const Section = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column-reverse;
  max-height: unset;

  .image-section {
    width: auto;
    margin: 1.75rem 1.75rem 0;
  }

  .text {
    ${FormTitle} {
      margin: 2rem 0 1.75rem;
    }

    text-align: center;
    margin: 0 1.75rem;
    width: auto;
  }

  ${media.laptop} {
    flex-direction: row;

    .back-button {
      flex: 1;

      button {
        align-self: flex-start;
        margin: 0 11.5rem 0;
      }
    }

    .text {
      ${FormTitle}, * {
        text-align: left;
        margin: 0 0 2rem 10rem;
      }

      flex: 2;
      padding: 0 0 5.5rem;
      justify-content: flex-end;
    }

    .image-section {
      margin: 5rem;
      align-items: flex-end;

      img {
        max-width: 686px;
        aspect-ratio: 1;
      }
    }
  }
`;
