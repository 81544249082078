import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { media } from "components/Theme/default-theme";

export const MarkdownBody = styled(ReactMarkdown)`
  line-height: 22px;

  h6,
  h5,
  strong {
    font-size: 16px;
    line-height: 28px;
    color: #000;
  }

  strong {
    font-weight: 500;
  }

  h5 {
    font-size: 20px;

    > * {
      font-size: 20px;
    }
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    font-weight: 400;
    margin: 20px 0 0;
  }

  p,
  ol,
  ul,
  li {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    margin: 0;
    color: #696057;

    > * {
      color: #696057;
    }
  }
`;

export const LeftSection = styled.div``;

export const RightSection = styled.div``;

export const StyledTableHeaderRows = styled.div`
  display: none;

  ${media.tablet} {
    display: flex;
  }
`;

export const StyledTableHeaderCell = styled.div`
  padding: 10px 8px;
  text-align: left;
  font-weight: 300;
  background: rgba(0, 0, 0, 0.05);
`;

export const StyledTableBodyRows = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const StyledTableBodyCell = styled.div`
  :first-child {
    padding: 10px 8px;
    text-align: left;
    font-weight: 300;
    background: rgba(0, 0, 0, 0.05);

    ${media.tablet} {
      background: transparent;
    }
  }
`;

export const StyledTable = styled.div`
  margin: 10px 0;
  border-top: 1px solid #b9b9b9;
  border-right: 1px solid #b9b9b9;

  ${media.tablet} {
    margin: 20px 0;
  }

  ${StyledTableHeaderCell},
  ${StyledTableBodyCell} {
    width: 100%;
    padding: 8px;
    vertical-align: unset;
    font-size: 16px;
    line-height: 28px;
    color: #696057;
    border-left: 1px solid #b9b9b9;
    border-bottom: 1px solid #b9b9b9;

    ${media.tablet} {
      :first-child {
        max-width: 30%;
      }
    }
  }
`;

export const Container = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;

  ${media.laptop} {
    padding: 60px 40px;
    flex-direction: row;
    max-width: 1680px;
    margin: 0 auto;
    ${RightSection}, ${LeftSection} {
      width: 50%;
    }

    ${MarkdownBody} {
      font-size: 16px;
      line-height: 28px;
    }

    ${RightSection} {
      padding-left: 250px;
    }
  }
`;
