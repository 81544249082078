import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { learnMoreBtnType } from "../../../components/LearnMoreBtn";
import { ListSection, ListSectionTitle, Padding, Link } from "../styles";

const query = graphql`
  query QuickLinksQuery {
    markdownRemark(fileAbsolutePath: { glob: "**/pageWidgets/quickLinks.md" }) {
      id
      frontmatter {
        links {
          linkTitle
          linkUrl
        }
      }
    }
  }
`;

const QuickLinks = () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const links = data?.markdownRemark?.frontmatter?.links || [];
        return (
          <div>
            <ListSectionTitle>Quick Links</ListSectionTitle>
            <Padding>
              <ListSection>
                {links.map((item, index) => (
                  <div key={`${item.linkTitle + index}`}>
                    <Link
                      btnUrl={item.linkUrl}
                      btnType={learnMoreBtnType.blackTextLink}
                    >
                      {item.linkTitle}
                    </Link>
                  </div>
                ))}
              </ListSection>
            </Padding>
          </div>
        );
      }}
    />
  );
};

export default QuickLinks;
