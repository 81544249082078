import React from "react";
import {
  Container,
  Header,
  StyledCaseStudiesList,
  StyledCaseStudy,
  StyledCaseStudyImage,
  StyledCaseStudyName,
  StyledSeparator,
} from "./styles";
import ROUTES from "../../consts/routes";

const SeeMoreCaseStudies = ({ caseStudiesList = [], header }) => {
  let caseStudies = [];
  if (caseStudiesList?.length > 0) {
    caseStudies = caseStudiesList;
  }
  return (
    <>
      <StyledSeparator
        data-aos="separator-animation"
        data-aos-offset="200"
        data-aos-once="true"
      />
      <Container>
        <Header>{header}</Header>

        <StyledCaseStudiesList>
          {caseStudies.map(
            ({ caseStudyImage, caseStudyName = "", caseStudySlug }, index) => (
              <StyledCaseStudy
                key={index}
                to={`${ROUTES.CASE_STUDIES}/${caseStudySlug}`}
                data-aos="fade-in"
                data-aos-delay={index * 1}
                data-aos-duration="400"
                data-aos-easing="ease-in"
                data-aos-once="true"
              >
                <StyledCaseStudyImage
                  item={caseStudyImage}
                  alt={caseStudyName}
                />
                <StyledCaseStudyName
                  to={`${ROUTES.CASE_STUDIES}/${caseStudySlug}`}
                >
                  {caseStudyName}
                </StyledCaseStudyName>
              </StyledCaseStudy>
            )
          )}
        </StyledCaseStudiesList>
      </Container>
    </>
  );
};

export default SeeMoreCaseStudies;
