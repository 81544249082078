import React from "react";
import { FormCheckbox, PolicyContainer, PolicyLink } from "./styles";

const PolicyCheckbox = (props) => (
  <>
    <FormCheckbox {...props} />
    <PolicyContainer>
      I agree to the
      <PolicyLink href="/business-policy" target="_blank">
        Savant Policies
      </PolicyLink>
    </PolicyContainer>
  </>
);

export default PolicyCheckbox;
