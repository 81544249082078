import React from "react";
import {
  BtnLink,
  Container,
  Item,
  ItemImage,
  ItemSubtitle,
  ItemsWrapper,
  ItemTextWrapper,
  ItemTitle,
} from "./styles";
import { learnMoreBtnType } from "components/LearnMoreBtn";

const TwoColumnIconsAndText = ({ leftSection, rightSection }) => {
  const renderItem = (item) => {
    const { title, subtitle, btnUrl, btnLabel, image } = item;
    return (
      <Item>
        {image && <ItemImage item={image} alt={`${title}-img`} />}
        <ItemTextWrapper>
          <ItemTitle>{title}</ItemTitle>
          <ItemSubtitle>{subtitle}</ItemSubtitle>
          <BtnLink btnUrl={btnUrl} btnType={learnMoreBtnType.blackTextLink}>
            {btnLabel}
          </BtnLink>
        </ItemTextWrapper>
      </Item>
    );
  };

  return (
    <Container>
      <ItemsWrapper>
        {renderItem(leftSection)}
        {renderItem(rightSection)}
      </ItemsWrapper>
    </Container>
  );
};

export default TwoColumnIconsAndText;
