import {
  StyledHamburgerOpened,
  StyledModal,
  StyledSlideOuter,
  StyledSlide,
  StyledSlider,
  StyledSliderImage,
} from "./styles";
import React, { useEffect, useState } from "react";
import SliderArrow from "components/SliderArrow";
//import useLockedBody from "../../utils/useLockedBody";

export const usePhotoGalleryPreview = () => {
  const [initialSlide, setInitialSlide] = useState(null);

  function handleClickBySlideIndex(slideIndex) {
    return () => setInitialSlide(slideIndex);
  }

  function onClose() {
    setInitialSlide(null);
  }

  return {
    initialSlide,
    handleClickBySlideIndex,
    onClose,
  };
};

const PhotoGalleryPreview = ({ images, onClose, initialSlide }) => {
  //const [, setLocked] = useLockedBody();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Number.isInteger(initialSlide)) {
      setIsOpen(true);
      //setLocked(true);
    }
  }, [initialSlide /* , setLocked */]);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SliderArrow />,
    prevArrow: <SliderArrow isPrev />,
    initialSlide,
  };

  function closeModal() {
    setIsOpen(false);
    //setLocked(false);
    onClose?.();
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={{
        overlay: {
          zIndex: 999999,
        },
      }}
      ariaHideApp={false}
    >
      <StyledHamburgerOpened onClick={closeModal} />
      <StyledSlider {...settings}>
        {images.map((image, index) => (
          <StyledSlideOuter key={`item-${index}`}>
            <StyledSlide>
              <StyledSliderImage
                item={image}
                key={`${image?.id}-${index}`}
                objectFit="contain"
              />
            </StyledSlide>
          </StyledSlideOuter>
        ))}
      </StyledSlider>
    </StyledModal>
  );
};

export default React.memo(PhotoGalleryPreview);
