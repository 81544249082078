import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { media } from "../../../components/Theme/default-theme";

export const MarkdownBody = styled(ReactMarkdown)`
  line-height: 22px;

  h6,
  h5,
  strong {
    font-size: 16px;
    line-height: 28px;
    color: #000;
  }

  strong {
    font-weight: 500;
  }

  h5 {
    font-size: 20px;

    > * {
      font-size: 20px;
    }
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    font-weight: 400;
    margin: 20px 0 0;
  }

  p,
  ol,
  ul,
  li {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    margin: 0;
    color: #696057;

    > * {
      color: #696057;
    }
  }

  ${media.laptop} {
    font-size: 16px;
    line-height: 28px;
  }
`;
