import styled from "styled-components";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";

export const StyledImage = styled(Image)`
  flex: 1 1 auto;
  max-width: 100px;

  ${media.tablet} {
    max-width: 100px;
  }

  ${media.laptop} {
    max-width: 125px;
  }

  ${media.desktop} {
    max-width: 160px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
