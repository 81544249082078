import React from "react";
import {
  Container,
  StyledTitle,
  StyledBackgroundImage,
  StyledImageWrapper,
  StyledImage,
  StyledParagraph,
  Column,
  Content,
  ButtonLink,
} from "./styles";
import WithAnimation from "../../utils/withAnimation";
import { Devices } from "../../consts/devices";
import { learnMoreBtnType } from "../../components/LearnMoreBtn";

const VaryDeviceImage = ({
  title,
  paragraph,
  backgroundImage,
  deviceImage,
  btnUrl,
  btnType = null,
}) => {
  return (
    <Container>
      <Column>
        <Content
          data-aos="fade"
          data-aos-delay="100"
          data-aos-duration="1000"
          data-aos-once="true"
        >
          <StyledTitle>{title}</StyledTitle>
          <StyledParagraph>{paragraph}</StyledParagraph>
          {btnUrl && (
            <ButtonLink
              btnUrl={btnUrl}
              btnType={btnType || learnMoreBtnType.orangeBtn}
            >
              learn more
            </ButtonLink>
          )}
        </Content>
      </Column>
      <Column>
        <StyledBackgroundImage item={backgroundImage} />
        <WithAnimation
          animations={[
            {
              animationAttributes: {
                "data-aos": "fade-up",
                "data-aos-offset": "200",
                "data-aos-delay": "500",
                "data-aos-easing": "ease-out",
                "data-aos-duration": "500",
                "data-aos-once": "true",
              },
              devices: [
                Devices.DESKTOP,
                Devices.LAPTOP,
                Devices.TABLET,
                Devices.MOBILE,
              ],
            },
          ]}
        >
          <StyledImageWrapper>
            <StyledImage alt="" item={deviceImage} />
          </StyledImageWrapper>
        </WithAnimation>
      </Column>
    </Container>
  );
};
export default VaryDeviceImage;
