import React, { memo } from "react";
import { Devices } from "../../../consts/devices";
import WithAnimation from "../../../utils/withAnimation";
import { Container, SelectedItemIndicator, StyledImage } from "./styles";

const IndicatorAnimationOptions = [
  {
    animationAttributes: {
      "data-aos": "fade-in",
      "data-aos-offset": "80",
      "data-aos-easing": "ease-out",
      "data-aos-duration": "800",
      "data-aos-once": "true",
    },
    devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
  },
];

const InteractiveItemNavigator = ({
  items = [],
  clickHandler,
  selectedItemIndex = 0,
}) => {
  if (items?.length === 0) {
    return null;
  }

  return items.map((items, idx) => (
    <Container
      key={`${items.image.id}-int-select-img-${idx}`}
      onClick={() => clickHandler(items, idx)}
    >
      <StyledImage item={items.image} active={idx === selectedItemIndex} />
      {idx === selectedItemIndex && (
        <WithAnimation animations={IndicatorAnimationOptions}>
          <SelectedItemIndicator />
        </WithAnimation>
      )}
    </Container>
  ));
};

export default memo(InteractiveItemNavigator);
