import React from "react";
import TCLAItem from "./TCLAItem";
import { Container, FlexContainer } from "./styles";

const TwoColumnLeftAlignedIconWithTextWidget = ({ tclaItem = [] }) => {
  const generateContent = () => {
    if (tclaItem.length === 0) {
      return null;
    }

    if (tclaItem.length === 1) {
      const { tclaIcon, tclaDescription } = tclaItem[0];
      return (
        <FlexContainer>
          <TCLAItem icon={tclaIcon} description={tclaDescription} />
        </FlexContainer>
      );
    }

    let content = [];
    for (let i = 0; i < tclaItem.length; i += 2) {
      const { tclaIcon: tclaIconL, tclaDescription: tclaDescriptionL } =
        tclaItem[i];

      const { tclaIcon: tclaIconR, tclaDescription: tclaDescriptionR } =
        i + 1 < tclaItem.length ? tclaItem[i + 1] : {};

      content.push(
        <FlexContainer key={`${tclaDescriptionL}-${tclaDescriptionR}-${i}`}>
          <TCLAItem icon={tclaIconL} description={tclaDescriptionL} />
          <TCLAItem
            icon={tclaIconR || {}}
            description={tclaDescriptionR || ""}
          />
        </FlexContainer>
      );
    }
    return content;
  };

  return <Container>{generateContent()}</Container>;
};

export default TwoColumnLeftAlignedIconWithTextWidget;
