import styled from "styled-components";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GridRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const GridItem = styled.div`
  min-height: 350px;
  margin-bottom: 15px;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: ${({ theme }) => theme.size.tablet}px) {
    width: ${(props) => (props.cellSize ? `74%` : `24%`)};
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 350px;
`;

export const Title = styled.h3`
  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
`;

export const Container = styled.div`
  padding: 0 20px 20px;

  ${media.tablet} {
    padding: 0 40px 40px;

    ${Title} {
      font-size: 40px;
      line-height: 50px;
      text-align: left;
    }

    ${GridRow} {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 15px;

      ${GridItem} {
        margin-bottom: 0;
      }
    }
  }

  ${media.laptop} {
    padding: 0 65px 50px;

    ${Title} {
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      margin: 50px 0;
    }

    ${GridRow} {
      margin-bottom: 30px;
    }
  }

  ${media.desktop} {
    padding: 0 0 60px;

    ${Title} {
      font-size: 35px;
      line-height: 44px;
      margin: 60px 0;
    }

    ${GridRow} {
      max-width: 1300px;
      margin-bottom: 30px;
    }
  }
`;
