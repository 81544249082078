import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import { getJustifyContentByContentAlign } from "../../utils/stylesHelpers";
import Image from "components/Image";
import { media } from "../../components/Theme/default-theme";

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Paragraph = styled(ReactMarkdown)`
  width: 100%;
  p {
    font-size: clamp(0.875rem, 0.6252rem + 0.8326vw, 1.5rem);
    margin: 0;
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: 325;
    line-height: 150%;
  }
`;

export const Title = styled.h2`
  width: 100%;
  font-size: clamp(1.3125rem, 0.8379rem + 1.582vw, 2.5rem);
  margin-top: 0;
  margin-bottom: 14px;
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-transform: uppercase;
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  height: 100%;
  margin: 15% auto;
`;

export const DeviceImage = styled(Image)`
  height: auto;
  width: 100%;
`;

export const DeviceImageContainer = styled.div`
  z-index: 3;
  display: flex;
  position: relative;
  width: 55%;
  height: auto;
  left: 0;
  right: 0;
  top: 0;
  margin: 20% auto 0;
  justify-content: space-around;
`;

export const BgImage = styled(Image)`
  height: 100%;
  width: 100%;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const BgImageSection = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  ${({ background, font_color }) => css`
    background: ${background || "#ffffff"};
    color: ${font_color || "#382e2b"};
  `}

  ${TextSection} {
    ${ButtonContainer} {
      justify-content: ${({ contentAlign }) =>
        getJustifyContentByContentAlign(contentAlign)};
    }

    ${Title}, ${Paragraph} {
      text-align: ${({ contentAlign }) => contentAlign || "center"};
    }
  }

  ${media.tablet} {
    ${DeviceImageContainer} {
      position: absolute;
      left: 5%;
      right: unset;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 33%;
      height: 66%;
    }

    ${BgImageSection} {
      position: relative;
      min-height: 25rem;
    }

    ${TextSection} {
      align-items: flex-start;
      justify-content: flex-start;
      width: 55%;
      height: 100%;
      align-self: flex-end;
      margin: clamp(2.25rem, -2.2237rem + 9.3202vw, 7.5625rem) 5%;

      ${ButtonContainer} {
        justify-content: ${({ contentAlign }) =>
          getJustifyContentByContentAlign(contentAlign)};
        padding: 0 1rem;
      }

      ${Title}, ${Paragraph} {
        text-align: ${({ contentAlign }) => contentAlign || "center"};
        padding: 0 1rem;
      }
    }
  }
`;
