import DeviceCentered from "components/DeviceCentered";
import DeviceWithBackground from "components/DeviceWithBackground";
import DeviceWithBackgroundA from "components/DeviceWithBackgroundA";
import FullBackgroundTextRight from "components/FullBackgroundTextRight";
import HeroImage from "components/HeroImage";
import HeroImageSlideshow from "components/HeroImageSlideshow";
import HeroVideo from "components/HeroVideo";
import ImageBackTextCentered from "components/ImageBackTextCentered";
import ImageLeftTextRight from "components/ImageLeftTextRight";
import JumpNavigation from "components/JumpNavigation";
import TextBlock from "components/TextBlock";
import ThreeColumnGridWithLabels from "components/ThreeColumnGridWithLabels";
import VaryDevice from "components/VaryDevice";

import LeftRightBlocks from "widgets/LeftRightBlocks";
import ProductGallery from "widgets/ProductGallery";
import TitleImgTextBlock from "widgets/TitleImgTextBlock";
import SeeMoreCaseStudies from "widgets/SeeMoreCaseStudies";
import PhotoGallery from "widgets/PhotoGallery";
import FourImageWithLabels from "widgets/FourImageWithLabels";
import FullBackgroundVaryDevice from "widgets/FullBackgroundVaryDevice";
import InPageContactForm from "widgets/InPageContactForm";
import VaryDeviceImage from "widgets/VaryDeviceImage";
import LeftAlignedText from "widgets/LeftAlignedText";
import LargeSpecifications from "widgets/LargeSpecifications";
import DevicesThreeAcross from "widgets/DevicesThreeAcross";
import VirtualWalkThrough from "widgets/VirtualWalkThrough";
import AppShotStaggered from "widgets/AppShotStaggered";
import ProductServiceHighlight from "widgets/ProductServiceHighlight";
import ImageCustomGrid from "widgets/ImageCustomGrid";
import ProductGrid from "widgets/ProductGrid";
import EULASection from "widgets/EULASection";
import CookieSection from "widgets/CookieSection";
import IconAndTextGrid from "widgets/IconAndTextGrid";
import ImageTiles from "widgets/ImageTiles";
import NewsTiles from "widgets/NewsTiles";
import Article from "widgets/Article";
import TwoColumnIconsAndText from "widgets/TwoColumnIconsAndText";
import BecomeDealerForm from "components/BecomeDealerForm";
import HeroBackground from "widgets/HeroBackground";
import ResourcesContent from "widgets/ResourcesContent";
import FourImagesInRow from "widgets/FourImagesInRow";
import ListWithIcons from "widgets/ListWithIcons";
import InteractiveSelect from "widgets/InteractiveSelect";
import MusicPlaylistWidget from "widgets/MusicPlaylistWidget";
import TwoColumnLeftAlignedIconWithTextWidget from "widgets/TwoColumnLeftAlignedIconWithText";
import TimelineWidget from "widgets/TimelineWidget";
import PowerIntakeFormWidget from "widgets/PowerIntakeFormWidget";

export const WidgetsMap = {
  leftRightBlocks: LeftRightBlocks,
  productGallerySection: ProductGallery,
  heroImageSection: HeroImage,
  textBlockSection: TextBlock,
  titleImgTextBlock: TitleImgTextBlock,
  seeMoreCaseStudies: SeeMoreCaseStudies,
  photoGallery: PhotoGallery,
  leftAlignedText: LeftAlignedText,
  imageBackTextCentered: ImageBackTextCentered,
  fourImageWithLabels: FourImageWithLabels,
  deviceCentered: DeviceCentered,
  threeColumnGridWithLabels: ThreeColumnGridWithLabels,
  virtualWalkThrough: VirtualWalkThrough,
  imageLeftTextRight: ImageLeftTextRight,
  fullBackgroundTextRight: FullBackgroundTextRight,
  deviceWithBackground: DeviceWithBackground,
  deviceWithBackgroundA: DeviceWithBackgroundA,
  fullBackgroundVaryDevice: FullBackgroundVaryDevice,
  varyDevice: VaryDevice,
  varyDeviceImage: VaryDeviceImage,
  largeSpecifications: LargeSpecifications,
  inPageContactForm: InPageContactForm,
  devicesThreeAcross: DevicesThreeAcross,
  heroVideo: HeroVideo,
  appShotStaggered: AppShotStaggered,
  productServiceHighlight: ProductServiceHighlight,
  imageCustomGrid: ImageCustomGrid,
  productGrid: ProductGrid,
  jumpNavigation: JumpNavigation,
  eulaSection: EULASection,
  cookieSection: CookieSection,
  iconAndTextGrid: IconAndTextGrid,
  imageTiles: ImageTiles,
  newsTiles: NewsTiles,
  articleWidget: Article,
  twoColumnIconsAndText: TwoColumnIconsAndText,
  becomeDealerForm: BecomeDealerForm,
  heroBackground: HeroBackground,
  resourcesContent: ResourcesContent,
  fourImagesInRow: FourImagesInRow,
  listWithIcons: ListWithIcons,
  interactiveSelect: InteractiveSelect,
  heroImageSlideshow: HeroImageSlideshow,
  musicPlaylist: MusicPlaylistWidget,
  twoColumnLeftAlignedIconWithText: TwoColumnLeftAlignedIconWithTextWidget,
  savantTimeline: TimelineWidget,
  powerIntakeForm: PowerIntakeFormWidget,
};
