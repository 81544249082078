import styled from "styled-components";
import Image from "components/Image";
import ReactMarkdown from "react-markdown";
import { media } from "components/Theme/default-theme";

export const StyledDescription = styled(ReactMarkdown)`
  > * {
    margin: 0;
    font-family: Gotham, sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #696057;
  }
`;

export const StyledIcon = styled(Image)``;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 90%;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 28px;
  width: 28px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  min-height: 246px;
  min-width: 320px;
  gap: 14px;

  ${media.tablet} {
    padding: 100px 82px;
    min-height: 276px;
    min-width: 600px;
  }
`;
