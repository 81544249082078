import React from "react";
import { Container, LeftSection, MarkdownBody, RightSection } from "./styles";
import QuickLinks from "../../pageWidgets/sidebarWidgets/QuickLinks";
import ContactSavantLinks from "../../pageWidgets/sidebarWidgets/ContactSavantLinks";
import WorldwideHeadquarters from "../../pageWidgets/sidebarWidgets/WorldwideHeadquarters";
import ViewFull from "../../components/ViewFull";

const EULASection = ({ eulaMarkdown, articleName }) => {
  return (
    <Container>
      <LeftSection>
        <ViewFull btnLabel={`+ View the full ${articleName}`}>
          <MarkdownBody>{eulaMarkdown}</MarkdownBody>
        </ViewFull>
      </LeftSection>
      <RightSection>
        <QuickLinks />
        <ContactSavantLinks />
        <WorldwideHeadquarters />
      </RightSection>
    </Container>
  );
};

export default EULASection;
