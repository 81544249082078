import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";

export const DefaultFontStyles = css`
  font-family: Gotham, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #382e2b;
`;

export const StyledAppImage = styled(Image)`
  display: inherit;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  text-align: center;
  margin: auto;
  z-index: 2;
`;

export const StyledDescription = styled(ReactMarkdown)`
  p {
    ${DefaultFontStyles}
    margin: 0;
    font-size: clamp(0.8rem, 2vw, 1.6rem);
    text-align: left;
    line-height: 150%;
  }
`;

export const StyledDescriptionTitle = styled.h2`
  ${DefaultFontStyles}
  font-size: clamp(0.8rem, 5vw, 2.5rem);
  text-align: left;
  margin: 0 0 20px;
  line-height: 111%;
`;

export const StyledDescriptionContainer = styled.div`
  display: inherit;
  flex-direction: column;
  flex: 1;
`;

export const StyledVerticalDivider = styled.div`
  width: 0;
  height: 16%;
  border: 0.5px solid #dbd9d6;
  position: absolute;
  ${({ position }) => (position === "top" ? "top: 0;" : "bottom: 0;")}
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
`;

export const StyledContentImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

export const StyledContentImageContainer = styled.div`
  display: inherit;
  flex-direction: column;
  flex: 1;
`;

export const StyledContentContainer = styled.span`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: ${({ image_align }) =>
    image_align === "left" ? "row" : "row-reverse"};
`;

export const StyledHeaderTitle = styled.h1`
  ${DefaultFontStyles}
  font-size: clamp(1.4rem, 5.5vw, 2.7rem);
  text-transform: uppercase;
  margin: 0;
`;

export const StyledHeaderTime = styled.h5`
  ${DefaultFontStyles}
  font-size: clamp(0.8rem, 2vw, 1.5rem);
  text-transform: uppercase;
  margin: 0;
`;

export const StyledHeaderContainer = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: auto;
  margin: 5% auto 0;
`;

export const Container = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  @media (orientation: portrait) {
    ${StyledContentContainer} {
      display: inherit;
      flex: 1;
      flex-direction: column-reverse;

      ${StyledDescriptionContainer} {
        width: 40%;
        height: 90%;
        margin: 5% 30%;
        align-items: center;
        * {
          max-width: 482px;
          min-width: 300px;
          text-align: center;
        }
      }

      ${StyledContentImageContainer} {
        ${StyledContentImage} {
          width: 90%;
          height: 90%;
          margin: 0 5% 10%;
        }
      }

      ${StyledVerticalDivider} {
        display: none;
      }

      ${StyledAppImage} {
        height: 60%;
        position: absolute !important;
        top: unset;
        bottom: 0;
      }

      ${media.tablet} {
        ${StyledAppImage} {
          height: 45%;
          position: absolute !important;
          top: unset;
          bottom: 0;
        }
      }
    }
  }

  @media (orientation: landscape) {
    ${StyledContentContainer} {
      margin-top: 3%;
      justify-content: space-between;

      ${StyledContentImageContainer} {
        max-width: 47vw;
        ${StyledContentImage} {
          height: auto;
          margin: 5% 0;
        }
      }

      ${StyledDescriptionContainer} {
        max-width: 30vw;
        margin: 0 5%;
        flex: 1;
        justify-content: center;
      }

      ${StyledAppImage} {
        height: 60%;
        position: absolute !important;
      }
    }
  }
`;
