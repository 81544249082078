import React, { useState } from "react";
import { Container, Overlay, ViewFullBtn } from "./styles";

const ViewFull = ({ btnLabel, children }) => {
  const [isFullView, setIsFullView] = useState(false);

  const handleClickViewFull = () => {
    setIsFullView((prev) => !prev);
  };

  return (
    <Container isFullView={isFullView}>
      {children}
      {!isFullView && (
        <>
          <Overlay />
          <ViewFullBtn onClick={handleClickViewFull}>{btnLabel}</ViewFullBtn>
        </>
      )}
    </Container>
  );
};

export default ViewFull;
