import React from "react";
import { StyledImage, StyledLinkButton } from "../styles";

const MusicLinkButton = ({
  image,
  label,
  type,
  onClickHandler,
  imageFit = "contain",
}) => (
  <StyledLinkButton type={type} onClick={onClickHandler}>
    {image && <StyledImage item={image} objectFit={imageFit} />}
    {!image && label && label}
  </StyledLinkButton>
);

export default MusicLinkButton;
