import styled from "styled-components";
import Link from "gatsby-link";
import Icon from "components/Icon";
import Image from "components/Image";
import { media } from "components/Theme/default-theme";

export const ItemLinkIcon = styled(Icon)`
  svg {
    width: 8px;
  }
`;

export const ItemLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #000;
  margin-top: 0;
  text-align: left;
  transition: all 0.35s ease-out;
`;

export const ItemLabelWrapper = styled.div`
  display: inherit;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  width: 100%;
`;

export const ItemImage = styled(Image)`
  display: inherit;
  flex: 1;
  width: 100%;
  aspect-ratio: 1;

  img {
    object-fit: cover !important;
  }
`;

export const Item = styled(Link)`
  display: inherit;
  flex-direction: column;
  border: 1px solid #dbd9d6;
  flex: 1/3;
  width: calc(33.333% - (44px * 2 / 3));

  @media screen and (max-width: 1300px) {
    width: calc(49% - (44px * 2 / 3));
    margin: 0 5px;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    margin: 0 5px;
  }

  :hover ${ItemLabel} {
    color: rgb(255, 95, 0);
  }
`;

export const ItemsWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 40px;
  width: auto;
  padding: 20px;

  @media screen and (max-width: 1300px) {
    justify-content: center;
  }
`;

export const SectionTitle = styled.h3`
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  color: #382e2b;
  padding: 50px 32px;
  border-bottom: 1px solid #ccc;
  width: 95%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  ${media.laptop} {
    max-width: 1300px;
    margin: 0 auto;
    padding: 10px 50px;
  }

  ${media.desktop} {
    max-width: 1577px;
    margin: 0 auto;
    padding: 0;

    ${SectionTitle} {
      padding: 50px 0 44px;
      width: 100%;
      max-width: inherit;
    }
  }
`;
