import React from "react";
import {
  Column,
  Container,
  Title,
  MarkdownBody,
  Section,
  LinksList,
  LinkListItem,
  Link,
} from "./styles";
import { learnMoreBtnType } from "../../components/LearnMoreBtn";

const ResourcesContent = ({ title, content, sections }) => {
  return (
    <Container>
      <Column>
        <Title>{title}</Title>
        <MarkdownBody>{content}</MarkdownBody>
      </Column>
      <Column>
        {sections.map(({ title, links }) => (
          <Section>
            <Title>{title}</Title>
            <LinksList>
              {links.map(({ linkLabel, linkUrl }) => (
                <LinkListItem>
                  <Link btnUrl={linkUrl} btnType={learnMoreBtnType.orangeLink}>
                    {linkLabel}
                  </Link>
                </LinkListItem>
              ))}
            </LinksList>
          </Section>
        ))}
      </Column>
    </Container>
  );
};

export default ResourcesContent;
