import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { MarkdownBody } from "./styles";
import { ListSectionTitle, Padding } from "../styles";

const query = graphql`
  query WorldwideHeadquartersQuery {
    markdownRemark(
      fileAbsolutePath: { glob: "**/pageWidgets/worldwideHeadquarters.md" }
    ) {
      id
      frontmatter {
        text
      }
    }
  }
`;

const WorldwideHeadquarters = () => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const text = data?.markdownRemark?.frontmatter?.text || "";
        return (
          <div>
            <ListSectionTitle>Worldwide Headquarters</ListSectionTitle>
            <Padding>
              <MarkdownBody>{text}</MarkdownBody>
            </Padding>
          </div>
        );
      }}
    />
  );
};

export default WorldwideHeadquarters;
