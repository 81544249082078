import styled from "styled-components";
import { media } from "components/Theme/default-theme";
import LearnMoreBtn from "components/LearnMoreBtn";
import ReactMarkdown from "react-markdown";
import Image from "components/Image";

export const Title = styled.h3`
  font-size: clamp(1rem, 0.4005rem + 1.9983vw, 2.5rem);
  font-style: normal;
  font-weight: 325;
  line-height: 125%;
  text-transform: uppercase;
  color: #382e2b;
  text-align: center;
`;

export const Description = styled(ReactMarkdown)`
  max-width: 890px;
  padding: 0 10px;
  margin: auto;

  p {
    font-style: normal;
    font-weight: 325;
    font-size: clamp(0.9375rem, 0.7127rem + 0.7494vw, 1.5rem);
    line-height: 150%;
    color: #382e2b;
    text-align: center;
  }
`;

export const StyledImageWrapper = styled.div`
  margin: 0;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
`;

export const StyledImage = styled(Image)`
  height: auto;
  width: 100%;
`;

export const BackgroundSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
`;

export const StyledStaticImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(180deg, #eeefed 0%, #dcdcdc 100%);
`;

export const StyledLearnMoreBtn = styled(LearnMoreBtn)`
  margin-top: 40px;
  margin-bottom: 40px;

  &:hover {
    color: #fff;
    background: #ff5f00;
  }
`;

export const Container = styled.div`
  position: relative;
  margin: 10% 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  ${media.tablet} {
    ${BackgroundSection} {
      ${StyledStaticImageWrapper} {
        margin-top: 20%;
        height: 80%;
      }
      ${StyledImageWrapper} {
        ${StyledImage} {
          width: 85%;
        }
      }
    }
  }
`;
