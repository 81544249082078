import { Observer } from "@racepointenergy/gatsby-theme-sitespark/src/components/Observer";
import PropTypes from "prop-types";
import React from "react";
import useWindowSize from "../../utils/useWindowSize";

import {
  Container,
  FluidImg,
  StyledHeader2,
  StyledMarkdown,
  StyledSection,
  StyledLearnMoreBtn,
  BackFone,
} from "./styles";

const HeroImage = ({
  heroTitle,
  subtitle,
  heroImage = null,
  heroFocusImage = null,
  btnUrl,
  btnLabel,
  heroImageLinkType,
  openInNewTab,
  background,
  fontColor,
}) => {
  const { height, width } = useWindowSize();
  let ImgComponent = null;

  if (height > width && heroFocusImage) {
    ImgComponent = <FluidImg item={heroFocusImage} alt="" />;
  } else if (heroImage) {
    ImgComponent = <FluidImg item={heroImage} alt="" />;
  } else {
    ImgComponent = <BackFone />;
  }

  return (
    <Container background={background} font_color={fontColor}>
      {ImgComponent}
      <Observer>
        <StyledSection>
          <StyledHeader2>{heroTitle}</StyledHeader2>
          <StyledMarkdown>{subtitle}</StyledMarkdown>
          {btnUrl && (
            <StyledLearnMoreBtn
              btnUrl={btnUrl}
              btnType={heroImageLinkType}
              openInNewTab={openInNewTab}
            >
              {btnLabel}
            </StyledLearnMoreBtn>
          )}
        </StyledSection>
      </Observer>
    </Container>
  );
};

HeroImage.defaultProps = {
  heroTitle: undefined,
  subtitle: undefined,
  heroImage: undefined,
  btnUrl: undefined,
  btnLabel: undefined,
  heroImageLinkType: undefined,
};

HeroImage.propTypes = {
  heroTitle: PropTypes.string,
  subtitle: PropTypes.string,
  heroImage: PropTypes.shape({
    childImageSharp: PropTypes.object,
    extension: PropTypes.string,
    id: PropTypes.string,
    publicURL: PropTypes.string,
  }),
  btnUrl: PropTypes.string,
  btnLabel: PropTypes.string,
  heroImageLinkType: PropTypes.oneOf([
    "orangeBtn",
    "fullOrangeBtn",
    "whiteBtn",
    "ghostBlackBtn",
    "textLink",
    "orangeLink",
    "blackTextLink",
    "whiteWithBrownBorder",
    "powerGreenOutline",
  ]),
};

export default HeroImage;
