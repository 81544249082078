import React from "react";
import {
  Content,
  ContentWrapper,
  Grid,
  ProductGridItem,
  ProductImage,
  ProductLabel,
  ProductDescription,
  ProductUrl,
} from "./styles";
import { Devices } from "../../consts/devices";
import WithAnimation from "../../utils/withAnimation";

const ProductGrid = ({ productGridItems, anotherAnimation }) => {
  const generateAnimation = (index) => {
    if (anotherAnimation) {
      return [
        {
          animationAttributes: {
            "data-aos": "fade-in",
            "data-aos-delay": "200",
            "data-aos-offset": "100",
            "data-aos-easing": "ease-out",
            "data-aos-duration": "600",
            "data-aos-once": "true",
          },
          devices: [Devices.MOBILE],
        },
        {
          animationAttributes: {
            "data-aos": "fade-in",
            "data-aos-delay": "400",
            "data-aos-offset": "100",
            "data-aos-easing": "ease-out",
            "data-aos-duration": "800",
            "data-aos-once": "true",
          },
          devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
        },
      ];
    }

    return [
      {
        animationAttributes: {
          "data-aos": "fade-in",
          "data-aos-delay": "300",
          "data-aos-offset": "200",
          "data-aos-easing": "ease-out",
          "data-aos-duration": "300",
          "data-aos-once": "true",
        },
        devices: [Devices.MOBILE],
      },
      {
        animationAttributes: {
          "data-aos": "fade-in",
          "data-aos-delay": "400",
          "data-aos-offset": "200",
          "data-aos-easing": "ease-out",
          "data-aos-duration": "500",
          "data-aos-once": "true",
        },
        devices: [Devices.DESKTOP, Devices.LAPTOP, Devices.TABLET],
      },
    ];
  };

  return (
    <Grid>
      {productGridItems.map(
        (
          { productImage, productLabel, productDescription, productUrl },
          index
        ) => {
          return (
            <ProductGridItem
              key={"product-grid-item-" + index}
              isAnother={anotherAnimation}
            >
              <WithAnimation animations={generateAnimation(index + 1)}>
                <ContentWrapper>
                  {productImage && (
                    <ProductImage
                      item={productImage}
                      alt={productLabel}
                      another={anotherAnimation}
                    />
                  )}
                  <Content>
                    {productLabel && (
                      <ProductLabel>{productLabel}</ProductLabel>
                    )}
                    {productDescription && (
                      <ProductDescription>
                        {productDescription}
                      </ProductDescription>
                    )}
                    {productUrl && (
                      <ProductUrl btnUrl={productUrl}>Learn more</ProductUrl>
                    )}
                  </Content>
                </ContentWrapper>
              </WithAnimation>
            </ProductGridItem>
          );
        }
      )}
    </Grid>
  );
};

export default ProductGrid;
