import styled from "styled-components";
import Icon from "components/Icon";
import ReactMarkdown from "react-markdown";
import { CloseIconWrapper } from "components/Modal/styles";
import { media } from "../../components/Theme/default-theme";

export const Title = styled.h3`
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: 325;
  font-size: 21px;
  line-height: 26px;
  text-transform: uppercase;
  color: #696057;
`;

export const ListWrapper = styled.div`
  margin-bottom: 42px;
`;

export const ListWrapperItem = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const ListWrapperItemText = styled(ReactMarkdown)`
  font-family: Gotham, serif;
  margin-left: 15px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  text-align: left;
  font-size: 15px;
  line-height: 23px;
  color: #382e2b;
  strong {
    color: #696057;
  }
  p {
    margin: 0;
  }
`;

export const ListWrapperItemIcon = styled(Icon)`
  svg {
    width: 27px;
    height: 27px;

    path {
      fill: #696057;
    }
  }
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
`;

export const Container = styled.div`
  padding: 180px 10px;

  ${CloseIconWrapper} {
    top: 10px;
    right: 10px;
    background-color: #aaa6;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    > i {
      height: 17px;
    }
  }

  ${media.tablet} {
    ${ContentWrapper} {
      max-width: 70%;
    }
  }
  ${media.laptop} {
    ${ListWrapperItemText} {
      font-size: 25px;
      line-height: 38px;
    }
    ${ContentWrapper} {
      max-width: 1100px;
    }
    ${Title} {
      font-size: 35px;
      line-height: 44px;
    }
    ${ListWrapper} {
      margin-bottom: 68px;
    }
    ${ListWrapperItemIcon} {
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;
