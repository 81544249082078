import React from "react";
import { ButtonTypes } from "components/Button/types";
import {
  Container,
  Content,
  StyledTitle,
  StyledParagraph,
  StyledForm,
  StyledFormRow,
  StyledFormGroup,
  StyledInput,
  StyledSubmitButton,
  StyledRequiredMobile,
  StyledRequiredLaptop,
} from "./styles";
//import { api } from "../../../defaults";
//import axios from "axios";
import { COUNTRY_OPTIONS } from "../../consts/selectOptions";
import { REGEX_VALIDATION_PATTERNS } from "../../consts/validations";

const InPageContactForm = () => {
  const onSubmit = async (evt) => {
    /*  const body = {
      ...data,
      country: data.country?.value,
    };

    try {
      await axios.post(
        `${api.baseUri}/domains-v1/form/sav/InPageContactForm`,
        body
      );
      reset();
    } catch (e) {
      console.error(e);
    } */
  };

  return (
    <Container>
      <Content>
        <StyledTitle>
          Starting a project?
          <div>Let’s talk.</div>
        </StyledTitle>
        <StyledParagraph>
          At vero eos et dolorum effugiendorum gratia magnum periculum adiit in
          oculis quidem exercitus quid ex eo delectu rerum, quem ad modum,
          quaeso, interpretaris?
        </StyledParagraph>
        <StyledParagraph>
          Sicine eos censes aut interrogari ut placet, inquam tum dicere.
        </StyledParagraph>
        <StyledRequiredMobile>*Required</StyledRequiredMobile>
      </Content>
      <StyledForm onSubmit={onSubmit}>
        <StyledFormRow>
          <StyledFormGroup>
            <StyledInput
              name="firstName"
              id="firstName"
              placeholder="First Name*"
              required={true}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInput
              name="lastName"
              id="lastName"
              placeholder="Last Name*"
              required={true}
            />
          </StyledFormGroup>
        </StyledFormRow>
        <StyledFormRow>
          <StyledFormGroup>
            <StyledInput
              pattern={REGEX_VALIDATION_PATTERNS.EMAIL}
              name="emailAddress"
              id="emailAddress"
              placeholder="Email Address*"
              type="email"
              required={true}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInput
              placeholder="Phone Number"
              name="phoneNumber"
              id="phoneNumber"
              required={true}
            />
          </StyledFormGroup>
        </StyledFormRow>
        <StyledFormRow>
          <StyledFormGroup>
            <StyledInput
              name="country"
              id="country"
              placeholder="Country"
              options={COUNTRY_OPTIONS}
              required={false}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInput
              placeholder="Zip / Postal Code"
              name="zipOrPostalCode"
              id="zipOrPostalCode"
              required={false}
            />
          </StyledFormGroup>
        </StyledFormRow>

        <StyledFormRow>
          <StyledFormGroup>
            <StyledInput
              name="contactPreference"
              id="contactPreference"
              placeholder="Contact Preference*"
              required={true}
            />
          </StyledFormGroup>
          <StyledFormGroup>
            <StyledInput
              name="productInterest"
              id="productInterest"
              placeholder="Product Interest"
              required={false}
            />
          </StyledFormGroup>
        </StyledFormRow>
        <StyledFormRow>
          <StyledFormGroup>
            <StyledInput
              placeholder="When do you want us to call you?*"
              name="whenToCall"
              id="whenToCall"
              required={true}
            />
          </StyledFormGroup>
          <StyledFormGroup />
        </StyledFormRow>
        <StyledRequiredLaptop>*Required</StyledRequiredLaptop>
        <StyledSubmitButton type={ButtonTypes.ORANGE} htmlType="submit">
          Submit
        </StyledSubmitButton>
      </StyledForm>
    </Container>
  );
};

export default InPageContactForm;
