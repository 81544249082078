import React from "react";
import SliderArrow, { ArrowTypes } from "components/SliderArrow";
import WithAnimation from "../../../utils/withAnimation";
import InteractiveItemContent from "../InteractiveItemContent";
import { InteractiveNavigatorAnimations } from "../constants";
import InteractiveImage from "./InteractiveImage";
import { DefaultSliderSettings } from "./constants";
import { PagingItem, Container, StyledSlider } from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InteractiveMobilecomponent = ({ items, largeText }) => {
  const sliderSettings = {
    nextArrow: <SliderArrow type={ArrowTypes.whiteToLightGray} />,
    prevArrow: <SliderArrow isPrev type={ArrowTypes.whiteToLightGray} />,
    customPaging: () => <PagingItem />,
    ...DefaultSliderSettings,
  };

  const SliderItem = ({ item, key }) => (
    <React.Fragment key={key}>
      <InteractiveImage image={item.image} />
      <InteractiveItemContent
        item={item}
        largeText={largeText}
        isMobile={true}
      />
    </React.Fragment>
  );

  return (
    <WithAnimation animations={InteractiveNavigatorAnimations}>
      <Container>
        <StyledSlider {...sliderSettings}>
          {(items?.length > 0 &&
            items.map((item, idx) => (
              <SliderItem item={item} key={`carousel-${item}-${idx}`} />
            ))) ||
            null}
        </StyledSlider>
      </Container>
    </WithAnimation>
  );
};

export default InteractiveMobilecomponent;
