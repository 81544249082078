import React from "react";
import {
  FullScreenContainer,
  Container,
  Header,
  StyledMarkdown,
} from "./styles";
import LearnMoreBtn, { learnMoreBtnType } from "../../components/LearnMoreBtn";

const LeftAlignedText = ({
  header,
  paragraph,
  btnUrl,
  btnType,
  background,
  openInNewTab,
}) => {
  const _btnType = btnType || learnMoreBtnType.fullOrangeBtn;
  return (
    <FullScreenContainer background={background}>
      <Container>
        <div data-aos="fade-in" data-aos-duration="600" data-aos-once="true">
          <Header>{header}</Header>
          <StyledMarkdown>{paragraph}</StyledMarkdown>
          <LearnMoreBtn
            btnUrl={btnUrl}
            btnType={_btnType}
            openInNewTab={openInNewTab}
          />
        </div>
      </Container>
    </FullScreenContainer>
  );
};
export default LeftAlignedText;
