import styled, { css } from "styled-components";
import Slider from "react-slick";
import { media } from "../../components/Theme/default-theme";
import Image from "components/Image";

const DefaultFontStyles = css`
  font-family: Gotham, sans-serif;
  font-weight: 325;
  font-style: normal;
`;

export const StyledSlider = styled(Slider)`
  .slick-prev,
  .slick-next {
    z-index: 1;

    :before {
      content: "";
    }
  }

  .slick-prev {
    left: 3% !important;
  }

  .slick-next {
    right: 3% !important;
  }
`;

export const DeviceItem = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DeviceImage = styled(Image)`
  max-width: 200px;
  width: 100%;
`;

export const DeviceLabel = styled.div`
  ${DefaultFontStyles}
  font-size: 20px;
  line-height: 125%;
  text-align: center;
  color: #382e2b;
  margin-top: 50px;
`;

export const DevicesWrapper = styled.div``;

export const Container = styled.div`
  background: linear-gradient(180deg, #eeefed 0%, #dcdcdc 100%);
  padding: 120px 0;

  ${media.tablet} {
    ${DeviceImage} {
      max-width: 300px;
    }
  }

  ${media.laptop} {
    ${DevicesWrapper} {
      max-width: 1100px;
      margin: 0 auto;

      ${DeviceImage} {
        max-width: 250px;
      }
    }
  }

  ${media.desktop} {
    ${DevicesWrapper} {
      max-width: 1300px;

      ${DeviceImage} {
        max-width: 310px;
      }
    }
  }
`;
