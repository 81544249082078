import React from "react";
import {
  Container,
  RowItem,
  RowsItemColumn,
  TableHeaderItem,
  TableHeader,
} from "./styles";

const TableInModal = ({ modalRows = [] }) => {
  return (
    <Container>
      <TableHeader>
        <TableHeaderItem flexNum={3}>Feature</TableHeaderItem>
        <TableHeaderItem flexNum={1} center>
          Base
        </TableHeaderItem>
        <TableHeaderItem flexNum={1} center>
          Essentials
        </TableHeaderItem>
        <TableHeaderItem flexNum={3}>Comments</TableHeaderItem>
      </TableHeader>
      {modalRows?.map((props, index) => (
        <TableModalRowItem {...props} key={index} index={index} />
      ))}
    </Container>
  );
};

const TableModalRowItem = ({ feature, base, essentials, comments, index }) => {
  return (
    <RowItem isOdd={index % 2 === 0}>
      <RowsItemColumn flexNum={3}>{feature}</RowsItemColumn>
      <RowsItemColumn flexNum={1} center>
        {base}
      </RowsItemColumn>
      <RowsItemColumn flexNum={1} center>
        {essentials}
      </RowsItemColumn>
      <RowsItemColumn flexNum={3}>{comments}</RowsItemColumn>
    </RowItem>
  );
};

export default TableInModal;
