import styled, { css } from "styled-components";
import { media } from "components/Theme/default-theme";
import Image from "components/Image";

const getLogotypePositionStyles = (position) => {
  const [x, y] = position.split(" ").reverse();

  const horizontal =
    x === "center" ? `left: 50%; transform: translateX(-50%);` : `${x}: 54px;`;
  const vertical = y
    ? `${y} : 48px;`
    : `top: 50%; transform: translateY(-50%);`;
  const center =
    x === "center" && !y ? `transform: translate(-50%, -50%);` : null;

  return css`
    position: absolute;
    z-index: 1;
    ${horizontal}
    ${vertical}
    ${center}
  `;
};

export const Logotype = styled(Image)`
  ${({ position }) => getLogotypePositionStyles(position)}
  width: 80px;

  ${media.tablet} {
    width: initial;
  }
`;

export const Background = styled(Image)`
  height: 588px;

  ${media.tablet} {
    height: 904px;
  }

  ${media.laptop} {
    height: auto;
  }
`;

export const Container = styled.div`
  position: relative;
  div {
    width: 100%;
  }
`;
