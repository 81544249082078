import styled, { css } from "styled-components";
import { media } from "../Theme/default-theme";

export const Container = styled.div`
  ${({ isFullView }) =>
    isFullView
      ? css`
          max-height: 10000vh;
          overflow: visible;
        `
      : css`
          max-height: 340px;
          overflow: hidden;
        `}
  margin-bottom: 30px;
  position: relative;
  transition: all 0.3s ease-in-out;

  ${media.laptop} {
    max-height: initial;
  }
`;

export const ViewFullBtn = styled.button`
  border: none;
  background: none;
  font-family: Gotham, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #696057;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);

  &:hover {
    color: #ff5f00;
  }

  ${media.laptop} {
    display: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 240px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.72) 44.27%,
    #fff 100%
  );

  ${media.laptop} {
    display: none;
  }
`;
