import { fadeIn } from "@racepointenergy/gatsby-theme-sitespark/src/components/Observer";
import ReactMarkdown from "react-markdown";
import styled, { css } from "styled-components";

import Image from "../Image";
import { media } from "../Theme/default-theme";
import LearnMoreBtn from "../LearnMoreBtn";

export const FluidImg = styled(Image)`
  width: 100%;
  position: absolute !important;
  left: 0;
  right: 0;
  height: 588px;
  top: 0;
`;

export const StyledSection = styled.section`
  display: flex;
  height: 588px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 0 1.75rem;
  ${fadeIn}
`;

export const StyledHeader2 = styled(ReactMarkdown)`
  font-size: 30px;
  line-height: 38px;
  font-style: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
  white-space: pre-wrap;
`;

export const StyledMarkdown = styled(ReactMarkdown)`
  margin: 0;

  p {
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    font-weight: 200;
  }
`;

export const StyledLearnMoreBtn = styled(LearnMoreBtn)``;

export const BackFone = styled.div`
  position: absolute !important;
  left: 0;
  right: 0;
  height: 588px;
  top: 0;

  ${media.tablet} {
    height: 904px;
  }

  ${media.laptop} {
    height: 670px;
  }

  ${media.desktop} {
    height: 820px;
  }
`;

export const Container = styled.div`
  position: relative;
  ${({ background, font_color }) => css`
    background: ${background || "#232020"};
    color: ${font_color || "#ffffff"};
  `}

  ${media.tablet} {
    ${FluidImg},
    ${StyledSection} {
      height: 904px;
    }

    ${StyledSection} {
      ${StyledLearnMoreBtn} {
        display: initial;
      }
    }

    ${StyledHeader2} {
      max-width: 690px;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
    }

    ${StyledMarkdown} {
      max-width: 690px;

      p {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  ${media.laptop} {
    ${FluidImg}, ${StyledSection} {
      height: 670px;
    }

    ${StyledHeader2}, ${StyledMarkdown} {
      max-width: 890px;
    }
  }

  ${media.desktop} {
    ${FluidImg}, ${StyledSection} {
      height: 820px;
    }

    ${StyledHeader2} {
      max-width: 1043px;
      font-size: 50px;
      line-height: 63px;
    }

    ${StyledMarkdown} {
      max-width: 1043px;

      p {
        font-size: 30px;
        line-height: 45px;
      }
    }
  }
`;
