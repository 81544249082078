import React from "react";
import {
  BackgroundSection,
  Container,
  Description,
  StyledImageWrapper,
  StyledImage,
  StyledStaticImageWrapper,
  Title,
  StyledLearnMoreBtn,
} from "./styles";
import { StaticImage } from "gatsby-plugin-image";

const ProductServiceHighlight = ({
  titleFirst,
  descriptionFirst,
  centeredImage,
  titleSecond,
  descriptionSecond,
  btnUrl,
}) => {
  return (
    <Container>
      <>
        <Title>{titleFirst}</Title>
        <Description>{descriptionFirst}</Description>
      </>
      <BackgroundSection>
        <StyledStaticImageWrapper>
          <StaticImage
            src="../../images/lines.png"
            alt={"Background Lines"}
            style={{ height: "100%", width: "100%" }}
          />
        </StyledStaticImageWrapper>
        <span
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-easing="ease-out"
          data-aos-once="true"
          style={{ width: "100%" }}
        >
          <StyledImageWrapper>
            <StyledImage item={centeredImage} alt="" objectFit="cover" />
          </StyledImageWrapper>
          <div data-aos="fade" data-aos-once="true">
            <Title>{titleSecond}</Title>
            <Description>{descriptionSecond}</Description>
            <StyledLearnMoreBtn btnUrl={btnUrl} />
          </div>
        </span>
      </BackgroundSection>
    </Container>
  );
};

export default ProductServiceHighlight;
