import React from "react";
import { Container, StyledImage } from "./styles";

const InteractiveImage = ({ image }) => {
  return (
    <Container>
      <StyledImage item={image} className="mobile-interactive-image" />
    </Container>
  );
};

export default InteractiveImage;
