import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import LearnMoreBtn from "components/LearnMoreBtn";
import { media } from "components/Theme/default-theme";

export const MarkdownBody = styled(ReactMarkdown)`
  line-height: 22px;

  h6,
  h5,
  strong {
    font-size: 16px;
    line-height: 28px;
    color: #000;
  }

  strong {
    font-weight: 500;
  }

  h5 {
    font-size: 20px;

    > * {
      font-size: 20px;
    }
  }

  h6,
  h5,
  h4,
  h3,
  h2,
  h1 {
    font-weight: 400;
    margin: 20px 0 0;
    line-height: 3rem;
  }

  p,
  ol,
  ul,
  li {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
    margin: 0;
    color: #696057;

    > * {
      color: #696057;
    }
    margin-bottom: 1em;
  }

  p {
  }
`;

export const LeftSection = styled.div``;

export const RightSection = styled.div``;

export const Padding = styled.div`
  padding: 20px 0;
`;

export const Link = styled(LearnMoreBtn)`
  text-align: left;
  text-transform: initial;
  text-decoration: none;
  color: #ff5f00;
  margin: 0;
  line-height: 25px;
  font-size: 16px;
  font-weight: 400;
`;

export const Container = styled.div`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;

  ${media.laptop} {
    padding: 60px 40px;
    flex-direction: row;
    max-width: 1680px;
    margin: 0 auto;
    ${RightSection}, ${LeftSection} {
      width: 50%;
    }

    ${MarkdownBody} {
      font-size: 16px;
      line-height: 28px;
    }

    ${RightSection} {
      padding-left: 250px;
    }
  }
`;
