import React from "react";
import {
  ButtonListContainer,
  Container,
  PlaylistContainer,
  StyledDescription,
  StyledIFrame,
  StyledTitle,
} from "./styles";
import MusicLinkButton from "./MusicLinkButton";

const MusicPlaylistWidget = ({
  musicPlaylistTitle,
  musicPlaylistButtons,
  musicPlaylistUrl,
  musicPlaylistDescription,
}) => {
  const handleButtonLinkClick = (url, target) => window.open(url, target);

  const renderButtonLinks = () =>
    musicPlaylistButtons.map(
      (
        {
          musicPlaylistBtnImage,
          musicPlaylistBtnLabel,
          musicPlaylistButtonType,
          musicPlaylistButtonUrl,
        },
        index
      ) => (
        <MusicLinkButton
          key={`${musicPlaylistBtnLabel}-${index}`}
          image={musicPlaylistBtnImage}
          label={musicPlaylistBtnLabel}
          type={musicPlaylistButtonType}
          onClickHandler={() =>
            handleButtonLinkClick(musicPlaylistButtonUrl, "_blank")
          }
        />
      )
    );

  return (
    <Container>
      <StyledTitle>{musicPlaylistTitle}</StyledTitle>
      {musicPlaylistButtons?.length && (
        <ButtonListContainer>{renderButtonLinks()}</ButtonListContainer>
      )}
      <PlaylistContainer>
        <StyledIFrame src={musicPlaylistUrl} />
        <StyledDescription>{musicPlaylistDescription}</StyledDescription>
      </PlaylistContainer>
    </Container>
  );
};

export default MusicPlaylistWidget;
