import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  Title,
  BgImageSection,
  BgImage,
  DeviceImage,
  Paragraph,
  ButtonContainer,
  TextSection,
  DeviceImageContainer,
} from "./styles";
import LearnMoreBtn from "../../components/LearnMoreBtn";
import defaultTheme from "components/Theme/default-theme";
import useWindowSize from "../../utils/useWindowSize";

const {
  size: { tablet: minTabletScreenWidth },
} = defaultTheme;

const AnimatedMobileDeviceImage = ({ image }) => (
  <DeviceImageContainer
    data-aos="fade-down"
    data-aos-offset="20"
    data-aos-easing="ease-in-sine"
    data-aos-delay="200"
    data-aos-once="true"
  >
    <DeviceImage item={image} objectFit="contain" />
  </DeviceImageContainer>
);

const AnimatedDeviceImage = ({ image }) => (
  <DeviceImageContainer
    data-aos="fade-right"
    data-aos-offset="40"
    data-aos-easing="ease-out"
    data-aos-delay="800"
    data-aos-once="true"
  >
    <DeviceImage item={image} objectFit="contain" />
  </DeviceImageContainer>
);

const DeviceWithBackground = ({
  backgroundImage,
  btnUrl,
  btnType,
  btnLabel,
  openInNewTab,
  contentAlign,
  desktopLargeText,
  deviceImage,
  paragraph,
  title,
  background,
  fontColor,
}) => {
  const { width: windowWidth } = useWindowSize();
  const CompDeviceImage =
    (windowWidth < minTabletScreenWidth && AnimatedMobileDeviceImage) ||
    AnimatedDeviceImage;

  return (
    <Container
      data-aos="fade"
      data-aos-offset="200"
      data-aos-delay="100"
      data-aos-easing="ease-out"
      data-aos-duration="800"
      data-aos-once="true"
      contentAlign={contentAlign}
      background={background}
      font_color={fontColor}
    >
      {deviceImage && <CompDeviceImage image={deviceImage} />}
      <BgImageSection>
        <BgImage
          alt=""
          item={backgroundImage}
          objectFit={deviceImage ? "cover" : "contain"}
        />
      </BgImageSection>
      <TextSection>
        <Title>{title}</Title>
        <Paragraph>{paragraph}</Paragraph>
        {btnUrl && (
          <ButtonContainer>
            <LearnMoreBtn
              btnUrl={btnUrl}
              btnType={btnType}
              openInNewTab={openInNewTab}
            >
              {btnLabel}
            </LearnMoreBtn>
          </ButtonContainer>
        )}
      </TextSection>
    </Container>
  );
};

DeviceWithBackground.propTypes = {
  backgroundImage: PropTypes.shape({
    childImageSharp: PropTypes.object,
    extension: PropTypes.string,
    id: PropTypes.string,
    publicURL: PropTypes.string,
  }),
  btnUrl: PropTypes.string,
  contentAlign: PropTypes.string,
  desktopLargeText: PropTypes.bool,
  deviceImage: PropTypes.shape({
    childImageSharp: PropTypes.object,
    extension: PropTypes.string,
    id: PropTypes.string,
    publicURL: PropTypes.string,
  }),
  paragraph: PropTypes.string,
  title: PropTypes.string,
};

export default DeviceWithBackground;
