//import { Observer } from "@racepointenergy/gatsby-theme-sitespark/src/components/Observer";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import {
  Container,
  ContentContainer,
  GridDescription,
  GridFooterWrapper,
  GridFooterLineText,
  GridItem,
  GridItemsWrapper,
  GridItemWrapper,
  GridTitle,
  ItemImage,
  ItemLabel,
  CTAButtonContainer,
  Video,
} from "./styles";
import ScrollObserver from "components/ScrollObserver";
import LearnMoreBtn from "components/LearnMoreBtn";

const GridFooterItem = ({ gridFooter }) => (
  <div
    data-aos="fade"
    data-aos-delay="100"
    data-aos-duration="400"
    data-aos-once="true"
  >
    <GridFooterWrapper>
      {gridFooter?.split("  ").map((line, idx) => (
        <GridFooterLineText
          key={`${line}-${idx}`}
        >{`${line}`}</GridFooterLineText>
      ))}
    </GridFooterWrapper>
  </div>
);

GridFooterItem.propTypes = {
  gridFooter: PropTypes.string,
};

const ThreeColumnGridWithLabels = ({
  gridColumnSize = "3",
  gridDescription,
  gridFooter,
  gridItems,
  gridTitle,
  background,
  fontColor,
  ctaBtnUrl,
  ctaBtnLabel,
  ctaBtnType,
  ctaOpenInNewTab,
  video,
  loopVideo,
  showReplayButton,
  autoplayVideo,
  showVideoControls,
}) => {
  const videoRef = useRef();
  const [runAnimation, setRunAnimation] = useState(false);
  const columnSize = (gridColumnSize && parseInt(gridColumnSize, 10)) || 3;
  const itemPercentWidth = Math.floor(85 / columnSize);

  return (
    <ScrollObserver
      cb={() => !runAnimation && setRunAnimation(true)}
      options={{ threshold: 0, rootMargin: "5px" }}
    >
      <Container
        item_width={itemPercentWidth}
        background={background}
        font_color={fontColor}
        animate={runAnimation}
      >
        {video && (
          <Video
            ref={videoRef}
            $imageBackground={background}
            url={video}
            autoplay={autoplayVideo}
            loop={loopVideo}
            volume={0}
            muted={true}
            object_fit="cover"
            controls={showVideoControls}
            showReplayButton={showReplayButton}
          />
        )}
        <ContentContainer>
          <div className="tcgwlHeaderContainer">
            <GridTitle>{gridTitle}</GridTitle>
            <GridDescription>{gridDescription}</GridDescription>
          </div>
          <GridItemsWrapper gridColumnSize={gridColumnSize}>
            {gridItems.map(
              ({ gridItemLabel, gridItemImage, gridItemImageFit }, index) => (
                <GridItemWrapper
                  key={gridItemLabel + index}
                  gridColumnSize={gridColumnSize}
                >
                  <GridItem>
                    {gridItemImage && (
                      <ItemImage
                        item={gridItemImage}
                        img_fit={gridItemImageFit}
                        alt=""
                      />
                    )}
                    {gridItemLabel && <ItemLabel>{gridItemLabel}</ItemLabel>}
                  </GridItem>
                </GridItemWrapper>
              )
            )}
          </GridItemsWrapper>
          {gridFooter && <GridFooterItem gridFooter={gridFooter} />}
          {ctaBtnUrl && (
            <CTAButtonContainer>
              <LearnMoreBtn
                btnUrl={ctaBtnUrl}
                btnType={ctaBtnType}
                openInNewTab={ctaOpenInNewTab}
              >
                {ctaBtnLabel}
              </LearnMoreBtn>
            </CTAButtonContainer>
          )}
        </ContentContainer>
      </Container>
    </ScrollObserver>
  );
};

ThreeColumnGridWithLabels.propTypes = {
  gridColumnSize: PropTypes.string,
  gridDescription: PropTypes.node,
  gridFooter: PropTypes.string,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      gridItemLabel: PropTypes.string,
      gridItemImage: PropTypes.shape({
        extension: PropTypes.string,
        publicURL: PropTypes.string,
      }),
    })
  ),
  gridTitle: PropTypes.node,
};

export default ThreeColumnGridWithLabels;
