import styled from "styled-components";
import Image from "components/Image";

export const ItemLabel = styled.a`
  flex: 2;
  font-style: normal;
  font-weight: lighter;
  font-size: 16pt;
  line-height: 1.75em;
  color: #ff5f00;
  margin-top: 0;
  width: 100%;
  text-align: center;
  transition: all 0.35s ease-out;
  text-decoration: unset;

  @media screen and (max-width: 900px) {
    font-size: 12pt;
  }

  @media screen and (max-width: 768px) {
    font-size: 12pt;
  }
`;

export const ItemDate = styled.div`
  display: flex;
  flex: 1;
  line-height: 1.75em;
  font-size: 16pt;
  margin: 0;
  font-weight: lighter;
  color: #696057;
  transition: all 0.35s ease-out;

  @media screen and (max-width: 900px) {
    font-size: 12pt;
  }

  @media screen and (max-width: 768px) {
    font-size: 12pt;
  }
`;

export const ItemLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const ItemImage = styled(Image)`
  width: 300px;
  height: 100px;
  margin: 20px 0;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;

  @media screen and (max-width: 900px) {
    flex: 1 0 100%;
  }

  @media screen and (max-width: 768px) {
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 90%;
    margin: 0 5%;
  }

  :hover ${ItemLabel} {
    text-decoration: underline;
  }
`;

export const Content = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 50px 0;
  gap: 2%;

  @media screen and (max-width: 768px) {
    gap: 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 15%;
  position: relative;

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;
