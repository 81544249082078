import React from "react";
import {
  Background,
  Container,
  Header,
  StyledImage,
  ImagesBackground,
  ImagesWrapper,
  ItemWrapper,
  Label,
  StyledMarkdown,
  TopContainer,
} from "./styles";

const FourImageWithLabels = ({
  header,
  fourImageAbout,
  fourImageList = [],
}) => {
  return (
    <Container>
      <TopContainer>
        <Header>{header}</Header>
        <StyledMarkdown>{fourImageAbout}</StyledMarkdown>
      </TopContainer>

      <ImagesBackground>
        <Background
          data-aos="slide-up"
          data-aos-duration="1000"
          data-aos-delay="1"
          data-aos-once="true"
          data-aos-easing="ease-in"
        />
        <ImagesWrapper>
          {fourImageList?.map(
            ({ fourImageItemImg, fourImageItemLabel, index }) => (
              <ItemWrapper
                key={fourImageItemLabel + index}
                data-aos="fade"
                data-aos-delay="200"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                <StyledImage
                  item={fourImageItemImg}
                  alt={"Background section image"}
                />
                <Label>{fourImageItemLabel}</Label>
              </ItemWrapper>
            )
          ) || null}
        </ImagesWrapper>
      </ImagesBackground>
    </Container>
  );
};

export default FourImageWithLabels;
