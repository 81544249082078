import React from "react";
import SliderArrow from "components/SliderArrow";
import {
  Container,
  GalleryHeader,
  PagingItem,
  ProductImage,
  ProductItem,
  ProductTitle,
  StyledSlider,
} from "./styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import defaultTheme from "components/Theme/default-theme";
import { ArrowTypes } from "components/SliderArrow";

const ProductGallery = ({ productsList, header }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SliderArrow type={ArrowTypes.whiteToLightGray} />,
    prevArrow: <SliderArrow isPrev type={ArrowTypes.whiteToLightGray} />,
    customPaging: () => <PagingItem />,
    responsive: [
      {
        breakpoint: defaultTheme.size.laptop,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: defaultTheme.size.tablet - 1,
        settings: {
          centerMode: false,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  if (!productsList) return <div>not found</div>;
  return (
    <Container>
      <GalleryHeader>{header}</GalleryHeader>
      <StyledSlider {...settings}>
        {productsList.map(({ productItemName, productItemImage }, index) => {
          return (
            <ProductItem key={`${productItemName}-${index}`}>
              {productItemImage && (
                <ProductImage
                  item={productItemImage}
                  alt={`${productItemName}`}
                  objectFit="contain"
                />
              )}
              <ProductTitle>{productItemName}</ProductTitle>
            </ProductItem>
          );
        })}
      </StyledSlider>
    </Container>
  );
};

export default ProductGallery;
