import React, { useEffect, useState } from "react";
import { ButtonTypes } from "../Button/types";
import ImageSlider from "./ImageSlider";
import {
  SlideImageLogo,
  StyledContainer,
  StyledHeroButton,
  StyledHeroContentContainer,
  OverlayGroup,
  ContentWrapper,
  TextGroup,
  StyledHeroNavigator,
  StyledHeroParagraph,
  StyledHeroTitle,
} from "./styles";

const HeroImageSlideshow = ({ ssHeroItem = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previousIndex, setPreviousIndex] = useState(null);

  const handleNavigation = (index) => {
    setPreviousIndex(currentIndex);
    setCurrentIndex(index);
  };

  const slideshowNavigator = (
    <StyledHeroNavigator>
      {ssHeroItem.map(({ ssHeroTitle }, index) => (
        <StyledHeroTitle
          key={`${ssHeroTitle}-${index}-hero-nav-title`}
          active={index === currentIndex}
          className={index === currentIndex ? "active-title" : "inactive-title"}
          onClick={() => handleNavigation(index)}
        >
          {ssHeroTitle}
        </StyledHeroTitle>
      ))}
    </StyledHeroNavigator>
  );

  const slideshowContent = ssHeroItem.map(
    (
      {
        ssHeroBtnLabel,
        ssHeroBtnType = ButtonTypes.ORANGE,
        ssHeroBtnURL,
        ssHeroParagraph,
        ssHeroImage,
        ssHeroLogo,
      },
      index
    ) => {
      return (
        <StyledHeroContentContainer
          key={`${ssHeroBtnType}-${ssHeroBtnLabel}-${
            ssHeroImage?.id ?? ""
          }-${index}`}
          className={
            index === currentIndex ? "active-content" : "inactive-content"
          }
        >
          {(ssHeroLogo && (
            <SlideImageLogo item={ssHeroLogo} objectFit="contain" alt="" />
          )) || <div style={{ display: "flex" }} />}
          <TextGroup>
            <StyledHeroParagraph>{ssHeroParagraph}</StyledHeroParagraph>
            <StyledHeroButton btnType={ssHeroBtnType} btnUrl={ssHeroBtnURL}>
              {ssHeroBtnLabel}
            </StyledHeroButton>
          </TextGroup>
        </StyledHeroContentContainer>
      );
    }
  );

  const updateCurrentIndex = () => {
    const nextIndex =
      currentIndex < ssHeroItem.length - 1 ? currentIndex + 1 : 0;
    handleNavigation(nextIndex);
  };

  useEffect(() => {
    const imageInterval = setTimeout(() => updateCurrentIndex(), 6000);
    return () => clearTimeout(imageInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <StyledContainer>
      <ImageSlider
        currentIndex={currentIndex}
        previousIndex={previousIndex}
        data={ssHeroItem}
      />
      <OverlayGroup>
        {slideshowNavigator}
        <ContentWrapper>{slideshowContent}</ContentWrapper>
      </OverlayGroup>
    </StyledContainer>
  );
};

export default HeroImageSlideshow;
